import { createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  businessRequest: ["businessId"],
  businessesCountRequest: [],
  businessAllRequest: ["options"],
  businessUpdateRequest: ["business"],
  businessDeleteRequest: ["businessId"],
  cacheBusinessesRequest: [],
  enterpriseCustomersRequest: ["businessId", "options"],
  enterpriseCustomersCountRequest: ["businessId"],
  approvedBusinessesDetailRequest: ["businessId"],
  enableCardPaymentsRequest: ["businessId"],
  overrideCardFeesRequest: ["businessId", "fees"],
  overrideMomoFeesRequest: ["businessId", "fees"],
  passingFeesOnRequest: ["businessId"],
  verificationStartedRequest: ["businessId"],
  businessVerificationRequest: ["businessId"],

  businessSuccess: ["business"],
  businessesCountSuccess: ["businessesCount"],
  businessAllSuccess: ["businesses"],
  businessUpdateSuccess: ["business"],
  businessDeleteSuccess: [],
  cacheBusinessesSuccess: ["cacheBusinesses"],
  enterpriseCustomersSuccess: ["enterpriseCustomers"],
  enterpriseCustomersCountSuccess: ["enterpriseCustomersCount"],
  approvedBusinessesDetailSuccess: ["approvedVerificationDetails"],
  enableCardPaymentsSuccess: ["business"],
  overrideCardFeesSuccess: ["business"],
  overrideMomoFeesSuccess: ["business"],
  passingFeesOnSuccess: ["business"],
  verificationStartedSuccess: ["verificationStarted"],
  businessVerificationSuccess: ["businessVerification"],

  businessFailure: ["error"],
  businessesCountFailure: ["error"],
  businessAllFailure: ["error"],
  businessUpdateFailure: ["error"],
  businessDeleteFailure: ["error"],
  cacheBusinessesFailure: ["error"],
  enterpriseCustomersFailure: ["error"],
  enterpriseCustomersCountFailure: ['error'],
  approvedBusinessesDetailFailure: ['error'],
  enableCardPaymentsFailure: ["error"],
  overrideMomoFeesFailure: ["error"],
  overrideCardFeesFailure: ["error"],
  passingFeesOnFailure: ["error"],
  verificationStartedFailure: ['error'],
  businessVerificationFailure: ['error'],
});

export const BusinessTypes = Types;
export default Creators;
