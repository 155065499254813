 
 import { call, put } from "redux-saga/effects";
import DLRsActions from "./dlrs-actions"
import * as api from "../../services/api"

export function* getAllDLRs(action) {
    const { options } = action
    const response = yield call(api.getAllDLRs, options)
    //success?
    if (response.ok) {
        yield put(DLRsActions.dlrsAllSuccess(response.data));
    } else {
        yield put(DLRsActions.dlrsAllFailure(response.data))
    }
}

export function* getFilteredDlrs(action) {
    const { businessId, options } = action
    const response = yield call(api.getFilteredDlrs, businessId, options)
    //success?
    if (response.ok) {
        yield put(DLRsActions.filteredDlrsSuccess(response.data));
    } else {
        yield put(DLRsActions.filteredDlrsFailure(response.data))
    }
}

export function* getAllDLRsCount() {
    const response = yield call(api.getAllDLRsCount)
    //success?
    if (response.ok) {
        yield put(DLRsActions.allDlrsCountSuccess(response.data));
    } else {
        yield put(DLRsActions.allDlrsCountFailure(response.data))
    }
}

export function* getFilteredCountDLRs(action) {
    const { businessId } = action;
    const response = yield call(api.getFilteredCountDLRs, businessId)
    //success?
    if (response.ok) {
        yield put(DLRsActions.filteredCountDlrsSuccess(response.data));
    } else {
        yield put(DLRsActions.filteredCountDlrsFailure(response.data))
    }
}
