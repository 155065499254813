import { takeLatest, all } from "redux-saga/effects";
/* ------------- Types ------------- */
import { SettlementTypes } from "./settlements/settlement-actions";
import { PaymentScheduleTypes } from "./scheduled-payments/payments-actions";
import { BusinessTypes } from "./business/business-actions";
import { PaymentTrackTypes } from "./payment-track/payment-track.actions";
import { PayoutChannelTypes } from "./payout-channel/payoutchannel-actions";
import { SubscriptionTypes } from "./subscriptions/subscriptions-actions";
import { PlanGroupTypes } from "./plan-group/plan-group-actions";
import { EnterpriseTypes } from "./enterprise/enterprise-actions";
import { IndexingTypes } from "./indexing/indexing-actions";
import { CountriesTypes } from "./countries/countries-actions";
import { CustomersTypes } from "./customers/customers-actions";
import { VerificationTypes } from "./verifications/verifications-actions";
import { SystemBankTypes } from "./system-banks/system-banks-actions";
import { DLRsTypes } from "./dlrs/dlrs-actions"
import { EmailTemplatesTypes } from "./email-template/email-template-actions";
import { AccountTypes } from "./account/account-action";

/* ------------- Sagas ------------- */
import { getSettlement, getAllSettlements, getSettlementCount, updateSettlement, deleteSettlement, getSettlementFilter, getOneSettlement, getProviderWallets, verifyBankAccount, disablePaystackOtp, confirmPaystackOtpDisabling, approvePendingSettlement, getPaymentSources, reversePendingSettlements } from "./settlements/settlements-saga";
import {
  getPaymentSchedule, getPaymentScheduleProjection, getPaymentScheduleRange, getPaymentScheduleSummary, getPaymentSchedules, updatePaymentSchedule,
  deletePaymentSchedule, notifyPaymentSchedule, getPaymentScheduleCount, getPaymentFilteredSchedules, getPaymentFilteredScheduleCount, markPaymentScheduleExternally
} from "./scheduled-payments/payments-sagas";
import {
  getBusiness, getBusinesses, getBusinessesCount, updateBusiness, deleteBusiness, cacheBusinessesList, enterpriseCustomersList,
  enterpriseCustomersListCount, getVerifiedBusinessesDetail, enableCardPayments, overrideCardFees, passingFeesOn, overrideMomoFees,
   verificationStartedRequest, businessVerificationRequest
} from "./business/business-saga";
import { getPaymentTrackRange } from "./payment-track/payment-track-saga";
import { getPayoutChannels } from "./payout-channel/payoutchannel-saga";
import { getSubscription, getAllSubscriptions, searchSubscriptions, getCountSubscriptions, dateFilterSubscriptions, pauseSubscription, cancelSubscription, rescheduleSubscription } from "./subscriptions/subscriptions-saga";
import { getPlanGroupsGraphedArchived, getPlanGroupsGraphedActive } from "./plan-group/plan-group-sagas";
import { getCredential, enableRequest, generateRequest, disableRequest } from "./enterprise/enterprise-sagas";
import { indexSubscribers, indexPayments, indexSignupRequest, indexCustomers, indexPaymentSchedules, indexPlans, indexBusinessCustomers, indexActivities, indexSettlements } from "./indexing/indexing-sagas";
import {
  getCountry, getCountries, updateCountry, getIdentityDocument, getIdentityDocuments,
  getIdentityDocumentsCount, updateIdentityDocument, createIdentityDocument, disableIdentityDocument, getZones
} from "./countries/countries-sagas";
import { applyCustomerImport, getCustomerImportsList, getCustomers, getCustomersCount, getListOfCustomerEntries, uploadDocument } from "./customers/customers-sagas";
import {
  getSystemBankList, getSystemBank, getSystemBanksCount, logoUpload, disableSystemBank, getListOfProviderBanks, getListOfProviders,
  getListOfBanksPerProvider, linkProviderToBank, unlinkProviderToBank
} from "./system-banks/system-banks-saga";
import {
  getPendingVerificationList, getApprovedVerificationList, getRejectedVerificationList, getPendingVerificationCount,
  getApprovedVerificationCount, getRejectedVerificationCount, getVerificationList, getVerification, approveVerification, rejectVerification, getVerificationProof, getImage
} from "./verifications/verifications-saga";
import { getAllDLRs, getFilteredDlrs, getAllDLRsCount, getFilteredCountDLRs } from "./dlrs/dlrs-sagas"
import { getEmailTemplates, getFilteredName, createTemplate, updateTemplate, deleteTemplate } from "./email-template/email-template-sagas";
import { getUserAccount } from "./account/account-saga";

// saga-method-import-needle

/* ------------- Connect Types To Sagas ------------- */
// import * as api from "./enterprise/enterprise-sagas";

export default function* root() {
  yield all([
    takeLatest(AccountTypes.ACCOUNT_REQUEST, getUserAccount),
    takeLatest(SettlementTypes.SETTLEMENT_REQUEST, getOneSettlement),
    takeLatest(SettlementTypes.SETTLEMENT_BUSINESS_REQUEST, getSettlement),
    takeLatest(SettlementTypes.SETTLEMENT_ALL_REQUEST, getAllSettlements),
    takeLatest(SettlementTypes.SETTLEMENT_COUNT_REQUEST, getSettlementCount),
    takeLatest(SettlementTypes.SETTLEMENT_FILTER_REQUEST, getSettlementFilter),
    takeLatest(SettlementTypes.SETTLEMENT_UPDATE_REQUEST, updateSettlement),
    takeLatest(SettlementTypes.SETTLEMENT_DELETE_REQUEST, deleteSettlement),
    takeLatest(SettlementTypes.PROVIDER_WALLET_REQUEST, getProviderWallets),
    takeLatest(SettlementTypes.VERIFY_ACCOUNT_REQUEST, verifyBankAccount),
    takeLatest(SettlementTypes.DISABLE_PAYSTACK_REQUEST, disablePaystackOtp),
    takeLatest(SettlementTypes.CONFIRM_DISABLE_REQUEST, confirmPaystackOtpDisabling),
    takeLatest(SettlementTypes.APPROVE_SETTLEMENT_REQUEST, approvePendingSettlement),
    takeLatest(SettlementTypes.PAYMENT_SOURCES_REQUEST, getPaymentSources),
    takeLatest(SettlementTypes.REVERSE_SETTLEMENT_REQUEST, reversePendingSettlements),

    takeLatest(PaymentScheduleTypes.PAYMENT_SCHEDULE_REQUEST, getPaymentSchedule),
    takeLatest(PaymentScheduleTypes.PAYMENT_SCHEDULE_ALL_REQUEST, getPaymentSchedules),
    takeLatest(PaymentScheduleTypes.PAYMENT_SCHEDULE_RANGE_REQUEST, getPaymentScheduleRange),
    takeLatest(PaymentScheduleTypes.PAYMENT_SCHEDULE_PROJECTION_REQUEST, getPaymentScheduleProjection),
    takeLatest(PaymentScheduleTypes.PAYMENT_SCHEDULE_SUMMARY_REQUEST, getPaymentScheduleSummary),
    takeLatest(PaymentScheduleTypes.PAYMENT_SCHEDULE_UPDATE_REQUEST, updatePaymentSchedule),
    takeLatest(PaymentScheduleTypes.PAYMENT_SCHEDULE_DELETE_REQUEST, deletePaymentSchedule),
    takeLatest(PaymentScheduleTypes.PAYMENT_SCHEDULE_NOTIFY_REQUEST, notifyPaymentSchedule),
    takeLatest(PaymentScheduleTypes.PAYMENT_SCHEDULE_COUNT_REQUEST, getPaymentScheduleCount),
    takeLatest(PaymentScheduleTypes.PAYMENT_SCHEDULE_FILTERED_REQUEST, getPaymentFilteredSchedules),
    takeLatest(PaymentScheduleTypes.PAYMENT_SCHEDULE_FILTERED_COUNT_REQUEST, getPaymentFilteredScheduleCount),
    takeLatest(PaymentScheduleTypes.MARK_PAYMENT_EXTERNALLY_REQUEST, markPaymentScheduleExternally),

    takeLatest(BusinessTypes.BUSINESS_REQUEST, getBusiness),
    takeLatest(BusinessTypes.BUSINESSES_COUNT_REQUEST, getBusinessesCount),
    takeLatest(BusinessTypes.BUSINESS_ALL_REQUEST, getBusinesses),
    takeLatest(BusinessTypes.BUSINESS_UPDATE_REQUEST, updateBusiness),
    takeLatest(BusinessTypes.BUSINESS_DELETE_REQUEST, deleteBusiness),
    takeLatest(BusinessTypes.CACHE_BUSINESSES_REQUEST, cacheBusinessesList),
    takeLatest(BusinessTypes.ENTERPRISE_CUSTOMERS_REQUEST, enterpriseCustomersList),
    takeLatest(BusinessTypes.ENTERPRISE_CUSTOMERS_COUNT_REQUEST, enterpriseCustomersListCount),
    takeLatest(BusinessTypes.APPROVED_BUSINESSES_DETAIL_REQUEST, getVerifiedBusinessesDetail),
    takeLatest(BusinessTypes.OVERRIDE_CARD_FEES_REQUEST, overrideCardFees),
    takeLatest(BusinessTypes.OVERRIDE_MOMO_FEES_REQUEST, overrideMomoFees),
    takeLatest(BusinessTypes.ENABLE_CARD_PAYMENTS_REQUEST, enableCardPayments),
    takeLatest(BusinessTypes.PASSING_FEES_ON_REQUEST, passingFeesOn),
    takeLatest(BusinessTypes.VERIFICATION_STARTED_REQUEST, verificationStartedRequest),
    takeLatest(BusinessTypes.BUSINESS_VERIFICATION_REQUEST, businessVerificationRequest),
    takeLatest(BusinessTypes.VERIFICATION_STARTED_REQUEST, verificationStartedRequest),
    takeLatest(BusinessTypes.BUSINESS_VERIFICATION_REQUEST, businessVerificationRequest),

    takeLatest(PaymentTrackTypes.PAYMENT_TRACK_RANGE_REQUEST, getPaymentTrackRange),

    takeLatest(PayoutChannelTypes.PAYOUT_CHANNEL_BUSINESS_REQUEST, getPayoutChannels),

    takeLatest(SubscriptionTypes.SUBSCRIPTION_REQUEST, getSubscription),
    takeLatest(SubscriptionTypes.SUBSCRIPTION_ALL_REQUEST, getAllSubscriptions),
    takeLatest(SubscriptionTypes.SUBSCRIPTION_SEARCH_REQUEST, searchSubscriptions),
    takeLatest(SubscriptionTypes.SUBSCRIPTION_DATE_SEARCH_REQUEST, dateFilterSubscriptions),
    takeLatest(SubscriptionTypes.SUBSCRIPTION_COUNT_REQUEST, getCountSubscriptions),
    takeLatest(SubscriptionTypes.PAUSE_SUBSCRIPTION_REQUEST, pauseSubscription),
    takeLatest(SubscriptionTypes.CANCEL_SUBSCRIPTION_REQUEST, cancelSubscription),
    takeLatest(SubscriptionTypes.RESCHEDULE_SUBSCRIPTION_REQUEST, rescheduleSubscription),

    takeLatest(PlanGroupTypes.PLAN_GROUP_GRAPHED_ACTIVE_REQUEST, getPlanGroupsGraphedActive),
    takeLatest(PlanGroupTypes.PLAN_GROUP_GRAPHED_ACTIVE_REQUEST, getPlanGroupsGraphedArchived),

    takeLatest(EnterpriseTypes.ENTERPRISE_REQUEST, getCredential),
    takeLatest(EnterpriseTypes.ENABLE_REQUEST, enableRequest),
    takeLatest(EnterpriseTypes.GENERATE_REQUEST, generateRequest),
    takeLatest(EnterpriseTypes.DISABLE_REQUEST, disableRequest),

    takeLatest(IndexingTypes.INDEX_SUBSCRIBERS_REQUEST, indexSubscribers),
    takeLatest(IndexingTypes.INDEX_PAYMENTS_REQUEST, indexPayments),
    takeLatest(IndexingTypes.INDEX_SIGNUP_REQUEST, indexSignupRequest),
    takeLatest(IndexingTypes.INDEX_CUSTOMERS_REQUEST, indexCustomers),
    takeLatest(IndexingTypes.INDEX_PAYMENT_SCHEDULES_REQUEST, indexPaymentSchedules),
    takeLatest(IndexingTypes.INDEX_PLANS_REQUEST, indexPlans),
    takeLatest(IndexingTypes.INDEX_BUSINESS_CUSTOMERS_REQUEST, indexBusinessCustomers),
    takeLatest(IndexingTypes.INDEX_ACTIVITIES_REQUEST, indexActivities),
    takeLatest(IndexingTypes.INDEX_SETTLEMENTS_REQUEST, indexSettlements),

    takeLatest(CountriesTypes.IDENTITY_DOCUMENT_REQUEST, getIdentityDocument),
    takeLatest(CountriesTypes.IDENTITY_DOCUMENTS_COUNT_REQUEST, getIdentityDocumentsCount),
    takeLatest(CountriesTypes.COUNTRY_REQUEST, getCountry),
    takeLatest(CountriesTypes.COUNTRIES_REQUEST, getCountries),
    takeLatest(CountriesTypes.IDENTITY_DOCUMENTS_REQUEST, getIdentityDocuments),
    takeLatest(CountriesTypes.UPDATE_DOCUMENT_REQUEST, updateIdentityDocument),
    takeLatest(CountriesTypes.CREATE_DOCUMENT_REQUEST, createIdentityDocument),
    takeLatest(CountriesTypes.DISABLE_IDENTITY_DOCUMENT_REQUEST, disableIdentityDocument),
    takeLatest(CountriesTypes.UPDATE_COUNTRY_REQUEST, updateCountry),
    takeLatest(CountriesTypes.ZONES_REQUEST, getZones),


    takeLatest(CustomersTypes.CUSTOMERS_REQUEST, getCustomers),
    takeLatest(CustomersTypes.CUSTOMERS_COUNT_REQUEST, getCustomersCount),
    takeLatest(CustomersTypes.CUSTOMER_IMPORTS_REQUEST, getCustomerImportsList),
    takeLatest(CustomersTypes.UPLOAD_REQUEST, uploadDocument),
    takeLatest(CustomersTypes.APPLY_UPLOAD, applyCustomerImport),
    takeLatest(CustomersTypes.ENTRIES_REQUEST, getListOfCustomerEntries),

    takeLatest(VerificationTypes.PENDING_VERIFICATION_LIST_REQUEST, getPendingVerificationList),
    takeLatest(VerificationTypes.APPROVED_VERIFICATION_LIST_REQUEST, getApprovedVerificationList),
    takeLatest(VerificationTypes.REJECTED_VERIFICATION_LIST_REQUEST, getRejectedVerificationList),
    takeLatest(VerificationTypes.PENDING_VERIFICATION_COUNT, getPendingVerificationCount),
    takeLatest(VerificationTypes.APPROVED_VERIFICATION_COUNT, getApprovedVerificationCount),
    takeLatest(VerificationTypes.REJECTED_VERIFICATION_COUNT, getRejectedVerificationCount),

    takeLatest(SystemBankTypes.SYSTEM_BANK_LIST_REQUEST, getSystemBankList),
    takeLatest(SystemBankTypes.SYSTEM_BANK_REQUEST, getSystemBank),
    takeLatest(SystemBankTypes.SYSTEM_BANKS_COUNT_REQUEST, getSystemBanksCount),
    takeLatest(SystemBankTypes.LOGO_REQUEST, logoUpload),
    takeLatest(SystemBankTypes.DISABLE_SYSTEM_BANK_REQUEST, disableSystemBank),
    takeLatest(SystemBankTypes.PROVIDER_BANKS_REQUEST, getListOfProviderBanks),
    takeLatest(SystemBankTypes.PROVIDERS_REQUEST, getListOfProviders),
    takeLatest(SystemBankTypes.BANK_PER_PROVIDER_REQUEST, getListOfBanksPerProvider),
    takeLatest(SystemBankTypes.LINK_REQUEST, linkProviderToBank),
    takeLatest(SystemBankTypes.UNLINK_REQUEST, unlinkProviderToBank),

    takeLatest(VerificationTypes.VERIFICATION_LIST_REQUEST, getVerificationList),
    takeLatest(VerificationTypes.VERIFICATION_REQUEST, getVerification),
    takeLatest(VerificationTypes.VERIFICATION_APPROVE_REQUEST, approveVerification),
    takeLatest(VerificationTypes.VERIFICATION_REJECT_REQUEST, rejectVerification),
    takeLatest(VerificationTypes.PROOF_REQUEST, getVerificationProof),
    takeLatest(VerificationTypes.IMAGE_REQUEST, getImage),

    takeLatest(DLRsTypes.FILTERED_DLRS_REQUEST, getFilteredDlrs),
    takeLatest(DLRsTypes.DLRS_ALL_REQUEST, getAllDLRs),
    takeLatest(DLRsTypes.ALL_DLRS_COUNT_REQUEST, getAllDLRsCount),
    takeLatest(DLRsTypes.FILTERED_COUNT_DLRS_REQUEST, getFilteredCountDLRs),

    takeLatest(EmailTemplatesTypes.EMAIL_TEMPLATE_REQUEST, getEmailTemplates),
    takeLatest(EmailTemplatesTypes.CREATE_EMAIL_TEMPLATE_REQUEST, createTemplate),
    takeLatest(EmailTemplatesTypes.UPDATE_EMAIL_TEMPLATE_REQUEST, updateTemplate),
    takeLatest(EmailTemplatesTypes.DELETE_EMAIL_TEMPLATE_REQUEST, deleteTemplate),
    takeLatest(EmailTemplatesTypes.FILTERED_REQUEST, getFilteredName),
  ]);
}
