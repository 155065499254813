import { call, put } from "redux-saga/effects";
import AccountActions from "./account-action";
import * as api from "../../services/api";

export function* getUserAccount() {
    // make the call to the api
    const response = yield call(api.getAccount);

    // success?
    if (response.ok) {
        yield put(AccountActions.accountSuccess(response.data));
    } else {
        yield put(AccountActions.accountFailure(response.data));
    }
}
