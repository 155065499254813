import React from 'react';
import img1 from '../../assets/images/reveknew.png';
import img2 from '../../assets/images/error_fallback_revkn.png';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

export function ErrorFallback({ error }) {
    const { t } = useTranslation();

    const goToDashboard = () => {
        window.location.replace('/settlements')
    };

    return (
        <div className="">
            <div className="access-box">
                <div className="mt-4 ml-4">
                    <img src={img1} alt="" width={150} />
                    <h5>{t("common.console_name")}</h5>
                </div>
                <div className="access-body text-center pt-3">
                    <img src={img2} alt={""} height={450} />
                    <div className="mt-4"></div>
                    <h1 className="text-dark font-bold" >{t("common.error_fallback_text")}</h1>
                    <div className="mt-4 mb-5">
                        <h2 className="mb-4">{t("common.error_fallback_subtext")}</h2>
                    </div>
                    <Button onClick={goToDashboard} style={{ color: 'white', backgroundColor: '#D0021B', borderRadius: 7 }} >
                        <i className="mr-2 fas fa-home fa-lg" aria-hidden="true"></i>
                        <b>{t("common.home")}</b>
                    </Button>
                </div>
            </div>
        </div>
    )
}