import { createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    pendingVerificationListRequest: ['options'],
    approvedVerificationListRequest: ['options'],
    rejectedVerificationListRequest: ['options'],
    pendingVerificationCount: [],
    approvedVerificationCount: [],
    rejectedVerificationCount: [],
//Detail page requests
    verificationListRequest: [""],
    verificationRequest: ["id"],
    verificationApproveRequest: ["id", "username"],
    verificationRejectRequest: ["id", "username", "message"],
    proofRequest:['id'],
    imageRequest:['id'],

    pendingVerificationListSuccess: ['pendingVerificationList'],
    approvedVerificationListSuccess: ['approvedVerificationList'],
    rejectedVerificationListSuccess: ['rejectedVerificationList'],
    pendingVerificationCountSuccess: ['count'],
    approvedVerificationCountSuccess: ['approvedCount'],
    rejectedVerificationCountSuccess: ['rejectedCount'],
    //Detail page success
    verificationListSuccess: ["verificationList"],
    verificationSuccess: ["verification"],
    verificationApproveSuccess: ["verificationApproved"],
    verificationRejectSuccess: ["verificationRejected"],
    proofSuccess: ['verificationProof'],
    imageSuccess: ['image'],

    pendingVerificationListFailure: ['error'],
    approvedVerificationListFailure: ['error'],
    rejectedVerificationListFailure: ['error'],
    pendingVerificationCountFailure: ['error'],
    approvedVerificationCountFailure: ['error'],
    rejectedVerificationCountFailure: ['error'],
    //Detail page failure
    verificationListFailure: ["error"],
    verificationFailure: ["error"],
    verificationApproveFailure: ["error"],
    verificationRejectFailure: ["error"],
    proofFailure:["error"],
    imageFailure:["error"],

})

export const VerificationTypes = Types
export default Creators