import { call, put } from 'redux-saga/effects'
import * as api from "../../services/api";
import SettlementActions from './settlement-actions'

export function* getOneSettlement(action) {
    const { settlementId } = action
    // make the call to the api
    const response = yield call(api.getOneSettlement, settlementId)

    // success?
    if (response.ok) {
        yield put(SettlementActions.settlementSuccess(response.data))
    } else {
        yield put(SettlementActions.settlementFailure(response.data))
    }
}

export function* getSettlement(action) {
    const { businessId, options } = action
    // make the call to the api
    const response = yield call(api.getSettlement, businessId, options)

    // success?
    if (response.ok) {
        yield put(SettlementActions.settlementBusinessSuccess(response.data))
    } else {
        yield put(SettlementActions.settlementBusinessFailure(response.data))
    }
}

export function* getAllSettlements(action) {
    const { options } = action
    // make the call to the api
    const response = yield call(api.getAllSettlements, options)

    // success?
    if (response.ok) {
        yield put(SettlementActions.settlementAllSuccess(response.data))
    } else {
        yield put(SettlementActions.settlementAllFailure(response.data))
    }
}

export function* getSettlementCount(action) {
    const { filter } = action
    // make the call to the api
    const response = yield call(api.getSettlementCount, filter)

    // success?
    if (response.ok) {
        yield put(SettlementActions.settlementCountSuccess(response.data))
    } else {
        yield put(SettlementActions.settlementCountFailure(response.data))
    }
}

export function* getSettlementFilter(action) {
    const { filter, options } = action
    // make the call to the api
    const response = yield call(api.getFilterSettlements, filter, options)

    // success?
    if (response.ok) {
        yield put(SettlementActions.settlementFilterSuccess(response.data))
    } else {
        yield put(SettlementActions.settlementFilterFailure(response.data))
    }
}

export function* updateSettlement(action) {
    const { settlement } = action
    // make the call to the api
    const idIsNotNull = !!settlement.id
    const response = yield call(idIsNotNull ? api.updateSettlement : api.createSettlement, settlement)

    // success?
    if (response.ok) {
        yield put(SettlementActions.settlementUpdateSuccess(response.data))
    } else {
        yield put(SettlementActions.settlementUpdateFailure(response.data))
    }
}

export function* deleteSettlement(action) {
    const { settlementId, options } = action
    // make the call to the api
    const response = yield call(api.deleteSettlement, settlementId)

    // success?
    if (response.ok) {
        yield put(SettlementActions.settlementDeleteSuccess());
        yield put(SettlementActions.settlementAllRequest(options));
        yield put(SettlementActions.settlementCountRequest());
    } else {
        yield put(SettlementActions.settlementDeleteFailure(response.data))
    }
}

export function* getProviderWallets() {
    const response = yield call(api.getProviderWallets);
    // success?
    if (response.ok) {
        yield put(SettlementActions.providerWalletSuccess(response.data))
    } else {
        yield put(SettlementActions.providerWalletFailure(response.data))
    }
}

export function* verifyBankAccount(action) {
    const { systemBankId, identifier } = action

    const response = yield call(api.verifyAccount, systemBankId, identifier)
    if (response.ok) {
        yield put(SettlementActions.verifyAccountSuccess(response.data))
    } else {
        yield put(SettlementActions.verifyAccountFailure(response.data))
    }
}

export function* disablePaystackOtp() {
    const response = yield call(api.disablePaystackOtp)
    if (response.ok) {
        yield put(SettlementActions.disablePaystackSuccess(response.data))
    } else {
        yield put(SettlementActions.disablePaystackFailure(response.data))
    }
}

export function* confirmPaystackOtpDisabling(action) {
    const { otp } = action
    const response = yield call(api.confirmOtpDisable, otp)
    if (response.ok) {
        yield put(SettlementActions.confirmDisableSuccess(response.data))
    } else {
        yield put(SettlementActions.confirmDisableFailure(response.data))
    }
}

export function* approvePendingSettlement(action) {
    const { settlementId } = action

    const response = yield call(api.approveSettlement, settlementId);
    // success?
    if (response.ok) {
        yield put(SettlementActions.approveSettlementSuccess(response.data))
    } else {
        yield put(SettlementActions.approveSettlementFailure(response.status))
    }
}

export function* getPaymentSources(action) {
    const { settlementId } = action

    const response = yield call(api.getPaymentSources, settlementId);
    // success?
    if (response.ok) {
        yield put(SettlementActions.paymentSourcesSuccess(response.data))
    } else {
        yield put(SettlementActions.paymentSourcesFailure(response.data))
    }
}

export function* reversePendingSettlements(action) {
    const { settlementId, data } = action

    const response = yield call(api.reverseSettlement, settlementId, data);
    // success?
    if (response.ok) {
        yield put(SettlementActions.reverseSettlementSuccess(response.data))
    } else {
        yield put(SettlementActions.reverseSettlementFailure(response.data))
    }
}