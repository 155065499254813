// import { NOTINITIALIZED } from 'dns'
import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { SubscriptionTypes } from './subscriptions-actions'

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    fetchingSubscription: null,
    fetchingSubscriptions: null,
    fetchingDateSearch: null,
    searchingAll: null,
    fetchingSearchCount: null,
    fetchingSubscriptionsCount: null,
    pausing: null,
    cancelling: null,
    rescheduling: null,

    subscription: null,
    subscriptions: null,
    dateSearchSubscriptions: null,
    searchSubscriptions: null,
    searchingCount: null,
    subscriptionsCount: null,
    pauseSubscription: null,
    cancelSubscription: null,
    rescheduleSubscription: null,



    errorSubscription: null,
    errorSearching: null,
    errorSubscriptions: null,
    errorDateSearch: null,
    errorSearchingCount: null,
    errorSubscriptionsCount: null,
    errorPausing: null,
    errorCancelling: null,
    errorRescheduling: null
})

/* ------------- Reducers ------------- */

// request the range of tracks from an api
export const request = (state) => { return { ...state, fetchingSubscription: true, subscription: null } }
export const allRequest = (state) => { return { ...state, fetchingSubscriptions: true, subscriptions: null } }
export const searchRequest = (state) => { return { ...state, searchingAll: true, searchSubscriptions: null } }
export const datesearchRequest = (state) => { return { ...state, fetchingDateSearch: true, dateSearchSubscriptions: null } }
export const searchSubscriptionCountRequest = (state) => { return { ...state, fetchingSearchCount: true, searchingCount: null } }
export const subscriptionsCountRequest = (state) => { return { ...state, fetchingSubscriptionsCount: true, subscriptionsCount: null } }
export const pauseSubscriptionRequest = (state) => { return { ...state, pausing: true, pauseSubscription: null } }
export const cancelSubscriptionRequest = (state) => { return { ...state, cancelling: true, cancelSubscription: null } }
export const rescheduleSubscriptionRequest = (state) => { return { ...state, rescheduling: true, reschedulingSubscription: null } }


// successful api lookup for range of schedules
export const success = (state, action) => {
    const { subscription } = action
    return {
        ...state,
        fetchingSubscription: false,
        errorSubscription: null,
        subscription
    }
}

export const allSuccess = (state, action) => {
    const { subscriptions } = action
    return {
        ...state,
        fetchingSubscriptions: false,
        errorSubscriptions: null,
        subscriptions
    }
}
export const searchSuccess = (state, action) => {
    const { searchSubscriptions } = action
    return {
        ...state,
        searchingAll: false,
        searchSubscriptions,
        errorSearching: null,
    }
}
export const subscriptionDateSearchSuccess = (state, action) => {
    const { dateSearchSubscriptions } = action
    return {
        ...state,
        fetchingDateSearch: false,
        dateSearchSubscriptions,
        errorDateSearch: null,
    }
}
export const searchSubscriptionCountSuccess = (state, action) => {
    const { searchingCount } = action
    return {
        ...state,
        fetchingSearchCount: false,
        searchingCount,
        errorSearchingCount: null,
    }
}
export const subcriptionCountSuccess = (state, action) => {
    const { count } = action
    return {
        ...state,
        fetchingSubscriptionsCount: false,
        subscriptionsCount: count,
        errorSubscriptionsCount: null,
    }
}

export const pauseSubscriptionSuccess = (state, action) => {
    const { data } = action
    return {
        ...state,
        pausing: false,
        errorPausing: null,
        pauseSubscription: data
    }
}

export const cancelSubscriptionSuccess = (state) => {
    return {
        ...state,
        cancelling: false,
        errorCancelling: null,
    }
}

export const rescheduleSubscriptionSuccess = (state, action) => {
    const { data } = action;
    return {
        ...state,
        rescheduling: false,
        errorRescheduling: null,
        rescheduleSubscription: data
    }
}


// Something went wrong fetching range of tracks
export const failure = (state, action) => {
    const { error } = action
    return {
        ...state,
        fetchingSubscription: false,
        errorSubscription: error,
        subscription: null
    }
}

export const allFailure = (state, action) => {
    const { error } = action
    return {
        ...state,
        fetchingSubscriptions: false,
        errorSubscriptions: error,
        subscriptions: null
    }
}
export const searchFailure = (state, action) => {
    const { error } = action
    return {
        ...state,
        searchingAll: false,
        searchSubscriptions: null,
        errorSearching: error,
    }
}
export const subscriptionDateSearchFailure = (state, action) => {
    const { error } = action
    return {
        ...state,
        fetchingDateSearch: false,
        dateSearchSubscriptions: null,
        errorDateSearch: error,
    }
}
export const searchSubscriptionCountFailure = (state, action) => {
    const { error } = action
    return {
        ...state,
        fetchingSearchCount: false,
        searchingCount: null,
        errorSearchingCount: error
    }
}
export const subscriptionsCountFailure = (state, action) => {
    const { error } = action
    return {
        ...state,
        fetchingSubscriptionsCount: false,
        subscriptionsCount: null,
        errorSubscriptionsCount: error
    }
}
export const failurePausingSubscription = (state, action) => {
    const { error } = action
    return {
        ...state, pauseSubscription: false, errorPausing: error, pausing: false
    }
};
export const failureCancellingSubscription = (state, action) => {
    const { error } = action
    return {
        ...state, cancelSubscription: false, errorCancelling: error, cancelling: false
    }
};
export const failureReschedulingSubscription = (state, action) => {
    const { error } = action
    return {
        ...state, rescheduleSubscription: false, errorCancelling: error, cancelling: false
    }
};


/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [SubscriptionTypes.SUBSCRIPTION_REQUEST]: request,
    [SubscriptionTypes.SUBSCRIPTION_ALL_REQUEST]: allRequest,
    [SubscriptionTypes.SUBSCRIPTION_SEARCH_REQUEST]: searchRequest,
    [SubscriptionTypes.SUBSCRIPTION_DATE_SEARCH_REQUEST]: datesearchRequest,
    [SubscriptionTypes.SUBSCRIPTION_SEARCH_COUNT_REQUEST]: searchSubscriptionCountRequest,
    [SubscriptionTypes.SUBSCRIPTION_COUNT_REQUEST]: subscriptionsCountRequest,
    [SubscriptionTypes.PAUSE_SUBSCRIPTION_REQUEST]: pauseSubscriptionRequest,
    [SubscriptionTypes.CANCEL_SUBSCRIPTION_REQUEST]: cancelSubscriptionRequest,
    [SubscriptionTypes.RESCHEDULE_SUBSCRIPTION_REQUEST]: rescheduleSubscriptionRequest,


    [SubscriptionTypes.SUBSCRIPTION_SUCCESS]: success,
    [SubscriptionTypes.SUBSCRIPTION_ALL_SUCCESS]: allSuccess,
    [SubscriptionTypes.SUBSCRIPTION_SEARCH_SUCCESS]: searchSuccess,
    [SubscriptionTypes.SUBSCRIPTION_DATE_SEARCH_SUCCESS]: subscriptionDateSearchSuccess,
    [SubscriptionTypes.SUBSCRIPTION_SEARCH_COUNT_SUCCESS]: searchSubscriptionCountSuccess,
    [SubscriptionTypes.SUBSCRIPTION_COUNT_SUCCESS]: subcriptionCountSuccess,
    [SubscriptionTypes.PAUSE_SUBSCRIPTION_SUCCESS]: pauseSubscriptionSuccess,
    [SubscriptionTypes.CANCEL_SUBSCRIPTION_SUCCESS]: cancelSubscriptionSuccess,
    [SubscriptionTypes.RESCHEDULE_SUBSCRIPTION_SUCCESS]: rescheduleSubscriptionSuccess,

    [SubscriptionTypes.SUBSCRIPTION_FAILURE]: failure,
    [SubscriptionTypes.SUBSCRIPTION_ALL_FAILURE]: allFailure,
    [SubscriptionTypes.SUBSCRIPTION_SEARCH_FAILURE]: searchFailure,
    [SubscriptionTypes.SUBSCRIPTION_DATE_SEARCH_FAILURE]: subscriptionDateSearchFailure,
    [SubscriptionTypes.SUBSCRIPTION_SEARCH_COUNT_FAILURE]: searchSubscriptionCountFailure,
    [SubscriptionTypes.SUBSCRIPTION_COUNT_FAILURE]: subscriptionsCountFailure,
    [SubscriptionTypes.PAUSE_SUBSCRIPTION_FAILURE]: failurePausingSubscription,
    [SubscriptionTypes.CANCEL_SUBSCRIPTION_FAILURE]: failureCancellingSubscription,
    [SubscriptionTypes.RESCHEDULE_SUBSCRIPTION_FAILURE]: failureReschedulingSubscription,


})
