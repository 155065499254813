import { call, put } from "redux-saga/effects";
import BusinessActions from "./business-actions";
import * as api from "../../services/api";

export function* getBusiness(action) {
  const { businessId } = action;
  // make the call to the api
  const response = yield call(api.getBusiness, businessId);

  // success?
  if (response.ok) {
    yield put(BusinessActions.businessSuccess(response.data));
  } else {
    yield put(BusinessActions.businessFailure(response.data));
  }
}

export function* getBusinesses(action) {
  const { options } = action;
  // make the call to the api
  const response = yield call(api.getBusinesses, options);

  // success?
  if (response.ok) {
    yield put(BusinessActions.businessAllSuccess(response.data));
  } else {
    yield put(BusinessActions.businessAllFailure(response.data));
  }
}
export function* getBusinessesCount() {
  // make the call to the api
  const response = yield call(api.getBusinessesCount);

  // success?
  if (response.ok) {
    yield put(BusinessActions.businessesCountSuccess(response.data));
  } else {
    yield put(BusinessActions.businessesCountFailure(response.data));
  }
}

export function* updateBusiness(action) {
  const { business } = action;
  // make the call to the api
  const idIsNotNull = !!business.id;
  const response = yield call(
    idIsNotNull ? api.updateBusiness : api.createBusiness,
    business
  );

  // success?
  if (response.ok) {
    yield put(BusinessActions.businessUpdateSuccess(response.data));
  } else {
    yield put(BusinessActions.businessUpdateFailure(response.data));
  }
}

export function* deleteBusiness(action) {
  const { businessId } = action;
  // make the call to the api
  const response = yield call(api.deleteBusiness, businessId);

  // success?
  if (response.ok) {
    yield put(BusinessActions.businessDeleteSuccess());
  } else {
    yield put(BusinessActions.businessDeleteFailure(response.data));
  }
}

export function* cacheBusinessesList(action) {
  // success ?
  const response = yield call(api.getCachedBusinesses)
  if (response.ok) {
    yield put(BusinessActions.cacheBusinessesSuccess(response.data));
  } else {
    yield put(BusinessActions.cacheBusinessesFailure(response.data));
  }
}

export function* enterpriseCustomersList(action) {
  const { businessId, options } = action
  // if success
  const response = yield call(api.getEnterpriseCustomers, businessId, options)
  if (response.ok) {
    yield put(BusinessActions.enterpriseCustomersSuccess(response.data));
  } else {
    yield put(BusinessActions.enterpriseCustomersFailure(response.data));
  }
}

export function* enterpriseCustomersListCount(action) {
  const { businessId } = action;
  // if success
  const response = yield call(api.getEnterpriseCustomersCount, businessId)
  if (response.ok) {
    yield put(BusinessActions.enterpriseCustomersCountSuccess(response.data));
  } else {
    yield put(BusinessActions.enterpriseCustomersCountFailure(response.data));
  }
}

export function* getVerifiedBusinessesDetail(action) {
  const { businessId } = action;
  //if success
  const response = yield call(api.approvedVerificationDetail, businessId)
  if (response.ok) {
    yield put(BusinessActions.approvedBusinessesDetailSuccess(response.data));
  } else {
    yield put(BusinessActions.approvedBusinessesDetailFailure(response.data));
  }
}


export function* enableCardPayments(action) {
  const { businessId } = action;
  // make the call to the api
  const response = yield call(api.enableCardPayments, businessId);
  // success?
  if (response.ok) {
    yield put(BusinessActions.enableCardPaymentsSuccess(response.data));
  } else {
    yield put(BusinessActions.enableCardPaymentsFailure(response.data));
  }
}

export function* overrideCardFees(action) {
  const { businessId, fees } = action;
  // make the call to the api
  const response = yield call(api.overrideCardFees, businessId, fees);
  // success?
  if (response.ok) {
    yield put(BusinessActions.overrideCardFeesSuccess(response.data));
  } else {
    yield put(BusinessActions.overrideCardFeesFailure(response.data));
  }
}

export function* overrideMomoFees(action) {
  const { businessId, fees } = action;
  // make the call to the api
  const response = yield call(api.overrideMomoFees, businessId, fees);
  // success?
  if (response.ok) {
    yield put(BusinessActions.overrideMomoFeesSuccess(response.data));
  } else {
    yield put(BusinessActions.overrideMomoFeesFailure(response.data));
  }
}

export function* passingFeesOn(action) {
  const { businessId } = action;
  // make the call to the api
  const response = yield call(api.passingFeesOn, businessId);
  // success?
  if (response.ok) {
    yield put(BusinessActions.passingFeesOnSuccess(response.data));
  } else {
    yield put(BusinessActions.passingFeesOnFailure(response.data));
  }
}

export function* verificationStartedRequest(action) {
  const { businessId } = action;
  // make the call to the api
  const response = yield call(api.verificationStartedRequest, businessId);
  // success?
  if (response.ok) {
    yield put(BusinessActions.verificationStartedSuccess(response.data));
  } else {
    yield put(BusinessActions.verificationStartedFailure(response.data));
  }
}
export function* businessVerificationRequest(action) {
  const { businessId } = action;
  // make the call to the api
  const response = yield call(api.businessVerificationRequest, businessId);
  // success?
  if (response.ok) {
    yield put(BusinessActions.businessVerificationSuccess(response.data));
  } else {
    yield put(BusinessActions.businessVerificationFailure(response.data));
  }
}
