import { lazy } from "react";

const SettlementsList = lazy(() => import('../views/settlements/index'))
const ScheduledPaymentsList = lazy(() => import('../views/scheduled-payments/scheduled-payments-list'));
const Businesses = lazy(() => import('../views/businesses/businesses'))
const Subscriptions = lazy(() => import('../views/subscriptions/subscriptions-list'));
const Indexing = lazy(() => import('../views/indexing/indexing'));
const Countries = lazy(() => import('../views/countries/countries'))
const Customers = lazy(() => import('../views/customers/customers'))
const Verification = lazy(() => import('../views/verifications/index-verification'))
const DLRs = lazy(() => import('../views/dlrs/index'))
const EmailTemplate = lazy(() => import('../views/email-template/index'))

let ThemeRoutes = [
  {
    path: "/settlements",
    name: "settlements",
    state: "settlementsPages",
    icon: "mdi mdi-wallet",
    component: SettlementsList,
  },
  {
    path: "/scheduled-payments",
    name: "schedule",
    icon: "mdi mdi-calendar",
    component: ScheduledPaymentsList,
  },
  {
    path: "/businesses",
    name: "business",
    icon: "mdi mdi-briefcase",
    component: Businesses,
  },
  {
    path: "/subscriptions",
    name: "subscriptions",
    icon: "mdi mdi-account",
    component: Subscriptions,
  },
  {
    path: "/indexing",
    name: "indexing",
    icon: "fas fa-list-alt",
    component: Indexing,
  },
  {
    path: "/countries",
    name: "countries",
    icon: "fas fa-map",
    component: Countries,
  },
  {
    path: "/customers",
    name: "customers",
    icon: "fas fa-users",
    component: Customers,
  }, {
    path: "/verifications",
    name: "verifications",
    icon: "fas fa-file",
    component: Verification,
  }, {
    path: "/dlrs",
    name: "dlrs",
    icon: "fas fa-globe",
    component: DLRs,
  },{
    path: "/emailTemplate",
    name: "email_template",
    icon: "fas fa-envelope",
    component: EmailTemplate,
  }

]

export default ThemeRoutes;