// a library to wrap and simplify api calls
import apisauce from "apisauce";

// ------
// STEP 1
// ------
//
// Create and configure an apisauce-based api object.
//
const api = apisauce.create({
  // base URL is read from the "constructor"
  baseURL: process.env.REACT_APP_API_URL,
  // here are some default headers
  headers: {
    "Cache-Control": "no-cache",
  },
  // 30 second timeout...
  timeout: 30000,
});

// ------
// STEP 2
// ------
//
// Define some functions that call the api.  The goal is to provide
// a thin wrapper of the api layer providing nicer feeling functions
// rather than "get", "post" and friends.
//
// I generally don't like wrapping the output at this level because
// sometimes specific actions need to be take on `403` or `401`, etc.
//
// Since we can't hide from that, we embrace it by getting out of the
// way at this level.
//
const setAuthToken = (userAuth) => api.setHeader("Authorization", "Bearer " + userAuth);
const removeAuthToken = () => api.deleteHeader("Authorization");
const getAccount = () => api.get('api/v1/account')

const getOneSettlement = (settlementId) => api.get("api/v1/settlements/" + settlementId);
const getSettlement = (businessId, options) => api.get("api/v1/settlements?walletId.equals=" + businessId, options);
const getAllSettlements = (options) => api.get("api/v1/settlements/", options);
const getSettlementCount = (filter) => api.get(`api/v1/settlements/count?${filter}`);
const createSettlement = (settlement) => api.post("api/v1/settlements", settlement);
const updateSettlement = (settlement) => api.put("api/v1/settlements", settlement);
const deleteSettlement = (settlement) => api.delete("api/v1/settlements/" + settlement);
const getFilterSettlements = (filter, options) => api.get(`api/v1/settlements?${filter}&${options}`,);
const getProviderWallets = () => api.get("api/v1/settlements/providers/wallets");
const verifyAccount = (systemBankId, identifier) => api.get(`api/v1/system-banks/verify/${systemBankId}/${identifier}`)
const approveSettlement = (settlementId) => api.patch(`api/v1/settlements/process/${settlementId}`)
const getPaymentSources = (settlementId) => api.get(`api/v1/settlements/sources/settlement/${settlementId}`)
const reverseSettlement = (settlementId, data) => api.post(`api/v1/settlements/reverse/${settlementId}`, data)


const getPaymentSchedule = (paymentScheduleId) => api.get("api/v1/payment-schedules/" + paymentScheduleId);
const getPaymentSchedules = (options) => api.get("api/v1/payment-schedules", options);
const getPaymentScheduleCount = () => api.get("api/v1/payment-schedules/count");
const getPaymentScheduleRange = (businessId, startDate, endDate, options) => api.get("api/v1/payment-schedules/business/" + businessId + "/" + startDate + "/" + endDate, options);
const getPaymentScheduleSummary = (businessId, startDate, endDate, currentDate
) => api.get("api/v1/payment-schedules/summary/" + businessId + "/" + startDate + "/" + endDate + "/" + currentDate);
const getPaymentScheduleProjection = (businessId, startDate, endDate) => api.get("api/v1/payment-schedules/projection/business/" + businessId + "/" + startDate + "/" + endDate);
const createPaymentSchedule = (paymentSchedule) => api.post("api/v1/payment-schedules", paymentSchedule);
const updatePaymentSchedule = (paymentSchedule) => api.put("api/v1/payment-schedules", paymentSchedule);
const deletePaymentSchedule = (paymentScheduleId) => api.delete("api/v1/payment-schedules/" + paymentScheduleId);
const notifyPaymentSchedule = (paymentScheduleId) => api.post("api/v1/payment-schedules/notify/" + paymentScheduleId);
const getFilterPaymentSchedule = (filter, options) => api.get(`api/v1/payment-schedules?` + filter, options);
const getFilterPaymentCount = (filter) => api.get(`api/v1/payment-schedules/count?` + filter);
const externallyMarkPayment = (id, receivedDate) => api.patch(`api/v1/payment-schedules/${id}/mark-externally-paid?receivedOn=${receivedDate}`)

const getBusiness = (businessId) => api.get("api/v1/businesses/" + businessId);
const getBusinesses = (options) => api.get("api/v1/businesses", options);
const getBusinessesCount = () => api.get("api/v1/businesses/count");
const createBusiness = (business) => api.post("api/v1/businesses", business);
const updateBusiness = (business) => api.put("api/v1/businesses", business);
const deleteBusiness = (businessId) => api.delete("api/v1/businesses/" + businessId);
const getCachedBusinesses = () => api.get("api/v1/businesses/cache");

const getEnterpriseCustomers = (businessId, options) => api.get("api/v1/business-customers?businessId.equals=" + businessId, options)
const getEnterpriseCustomersCount = (businessId) => api.get("api/v1/business-customers/count?businessId.equals=" + businessId)

const getPaymentTrackRange = (businessId, startDate, endDate, options) => api.get("api/v1/payment-tracks/business/" + businessId + "/" + startDate + "/" + endDate, options);

const getPayoutChannel = (payoutChannelId) => api.get("api/v1/payout-channels/" + payoutChannelId);
const getPayoutChannels = (businessId, options) => api.get("api/v1/payout-channels?businessId.equals=" + businessId + "&status.equals=ACTIVE", options);
const createPayoutChannel = (payoutChannel) => api.post("api/v1/payout-channels", payoutChannel);
const updatePayoutChannel = (payoutChannel) => api.put("api/v1/payout-channels", payoutChannel);
const deletePayoutChannel = (payoutChannelId) => api.delete("api/v1/payout-channels/" + payoutChannelId);
const archivePayoutChannel = (payoutChannelId, username) => api.patch("api/v1/payout-channels/archive/" + payoutChannelId + "/" + username);

const getSubscription = (subscriptionId) => api.get("api/v1/subscribers/" + subscriptionId);
const getAllSubscriptions = (options) => api.get("api/v1/subscribers", options);
const getCountSubscriptions = (options) => api.get("api/v1/subscribers/count", options);
const searchSubscriptions = (query, filters, options) => api.get("api/v1/_search/subscribers?query=" + query + "&filters=" + filters, options);
const dateFilterSubscriptions = (query, businessId, startDate, endDate, options) => api.get("api/v1/_search/subscribers/" + businessId + "/" + startDate + "/" + endDate + "?query=" + query, options);
const searchSubscriptionCount = (query, filters) => api.get("api/v1/_search/subscribers/count?query=" + query + "&filters=" + filters);
const pauseSubscription = (subscriptionId) => api.patch(`api/v1/subscribers/${subscriptionId}/pause`)
const cancelSubscription = (subscriptionId) => api.patch(`api/v1/subscribers/${subscriptionId}/cancel`)
const rescheduleSubscription = (subscriptionId, date) => api.patch(`api/v1/subscribers/reschedule/${subscriptionId}/` + date)

const getPlanGroupsGraphedActive = (businessId) => api.get('api/v1/plan-groups/graphed/active/' + businessId)
const getPlanGroupsGraphedArchived = (businessId) => api.get('api/v1/plan-groups/graphed/archived/' + businessId)

const getCredential = (businessId) => api.get("api/v1/credential/" + businessId);
const enableRequest = (businessId) => api.post("api/v1/credential/enable/" + businessId);
const generateRequest = (businessId) => api.patch("api/v1/credential/rotate/" + businessId);
const disableRequest = (businessId) => api.patch("api/v1/credential/disable/" + businessId);

const indexSubscribers = () => api.patch("api/v1/subscribers/index-all");
const indexPayments = () => api.patch("api/v1/payment-tracks/index-all");
const indexSignupRequests = () => api.patch("api/v1/self-signups/index-all");
const indexCustomers = () => api.patch("api/v1/customers/index-all");
const indexPaymentsSchedules = () => api.patch("api/v1/payment-schedules/index-all");
const indexPlans = () => api.patch("api/v1/plan-groups/index-all");
const indexBusinessCustomers = () => api.patch("api/v1/customers/business/index-all")
const indexActivities = () => api.patch("api/v1/activities/index-all")
const indexAllSettlements = () => api.patch("api/v1/settlements/index-all")

const getCountry = (id) => api.get("api/v1/countries/" + id)
const getCountries = (options) => api.get("api/v1/countries", options);
const updateCountry = (countryId, countryData) => api.put(`api/v1/countries/${countryId}`, countryData)
const getIdentityDocument = (id) => api.get("api/v1/identity-documents/" + id);
const getIdentityDocuments = (countryId, options) => api.get("api/v1/identity-documents?countryId.equals=" + countryId + options);
const createIdentityDocuments = (identityDocument) => api.post("api/v1/identity-documents", identityDocument);
const updateIdentityDocuments = (id, identityDocument) => api.put(`api/v1/identity-documents/${id}`, identityDocument);
const disableIdentityDocuments = (id) => api.patch(`api/v1/identity-documents/${id}/deactivate`);
const getIdentityDocumentsCount = (countryId) => api.get("api/v1/identity-documents/count?countryId.equals=" + countryId);
const getZones = () => api.get("api/v1/countries/zones");

// const getCustomer = (customerId) => api.get('api/v1/customers/' + customerId);
const getCustomers = (options) => api.get('api/v1/customers', options);
const getCustomersCount = () => api.get('api/v1/customers/count');
// const createCustomer = (customer) => api.post('api/v1/customers', customer);
// const updateCustomer = (customer) => api.put('api/v1/customers', customer);
// const deleteCustomer = (customerId) => api.delete('api/v1/customers/' + customerId);

const getPendingVerifications = (options) => api.get("api/v1/identity-verif-requests?status.equals=PENDING", options);
const getPendingVerificationsCount = () => api.get("api/v1/identity-verif-requests/count?status.equals=PENDING");
const getApprovedVerifications = (options) => api.get("api/v1/identity-verif-requests?status.equals=APPROVED", options);
const getApprovedVerificationsCount = () => api.get("api/v1/identity-verif-requests/count?status.equals=APPROVED");
const getRejectedVerifications = (options) => api.get("api/v1/identity-verif-requests?status.equals=REJECTED", options);
const getRejectedVerificationsCount = () => api.get("api/v1/identity-verif-requests/count?status.equals=REJECTED");
const pendingVerifications = () => api.get('api/v1/identity-verif-requests?status.equals=PENDING')
const pendingVerification = (id) => api.get('api/v1/identity-verif-requests/' + id)

const getVerificationProofRequest = (requestId) => api.get(`api/v1/identity-proofs/request/${requestId}`)
const getIdentityImage = (proofId) => api.get('api/v1/identity-proofs/' + proofId + '/image')
// const getIdentityImage = (proofId) => api.get('api/v1/identity-proofs/' + proofId + '/image', {}, {headers:{"content-type":"image/png"}, responseType: "arraybuffer"})
const approveVerification = (id, username) => api.patch(`api/v1/identity-verif-requests/${id}/${username}/approve`)
const rejectVerification = (id, username, message) => api.patch('api/v1/identity-verif-requests/' + id + '/' + username +
  '/reject?rejectionMsg=' + message)
const approvedVerificationDetail = (businessId) => api.get(`api/v1/identity-verif-requests/approval/${businessId}`);

const getSystemBanks = (countryId, options) => api.get("api/v1/system-banks?countryId.equals=" + countryId, options);
// const getSystemBank = (Id) => api.get(`api/v1/system-banks?Id.equals=${Id}`);
const getSystemBank = (Id) => api.get(`api/v1/system-banks/${Id}`);
const getSystemBanksCount = (countryId) => api.get("api/v1/system-banks/count?countryId.equals=" + countryId);
const logoUpload = (bankId, file) => api.post(`api/v1/system-banks/upload/${bankId}/logo`, file, {
  headers: {
    'content-type': 'multipart/form-data'
  }
})
const disableSystemBank = (id) => api.patch(`api/v1/system-banks/${id}/deactivate`)
const getProviderBanks = (systemBankId) => api.get("api/v1/banks?systemBankId.equals=" + systemBankId)
const getProviders = () => api.get(`api/v1/settlements/providers`)
const getBankPerProvider = (paymentProvider) => api.get(`api/v1/banks/provider/${paymentProvider}`)
const linkProviderToBank = (systemBankId, providerBankId) => api.patch(`api/v1/system-banks/${systemBankId}/link/${providerBankId}`)
const unlinkProviderBank = (systemBankId, providerBankId) => api.patch(`api/v1/system-banks/${systemBankId}/unlink/${providerBankId}`)
const disablePaystackOtp = () => api.patch("api/v1/system-banks/paystack/disable-otp")
const confirmOtpDisable = (otp) => api.patch(`api/v1/system-banks/paystack/confirm-disable-otp/${otp}`)

const getCustomerImports = (businessId, options) => api.get("api/v1/customer-imports?businessId.equals=" + businessId, options);
const uploadCustomerImports = (businessId, file) => api.post(`api/v1/customer-imports/upload/${businessId}`, file, {
  headers: {
    'content-type': 'multipart/form-data'
  }
});
const applyImport = (id) => api.put(`api/v1/customer-imports/apply/${id}`)
const getEntriesList = (importId) => api.get(`api/v1/customer-import-entries/imports/${importId}`)

const getFilteredDlrs = (businessId, options) => api.get(`api/v1/sms-dlrs?businessId.equals= ${businessId}`, options)
const getAllDLRs = (options) => api.get("api/v1/sms-dlrs", options)
const getAllDLRsCount = () => api.get("api/v1/sms-dlrs/count")
const getFilteredCountDLRs = (businessId) => api.get("api/v1/sms-dlrs/count?businessId.equals=" + businessId)

const getEmailTemplate = () => api.get(`api/v1/email-templates`)
const getFilteredNameEmailTemplate = (filter) => api.get(`api/v1/email-templates?${filter}`)
const createEmailTemplate = (file) => api.post(`api/v1/email-templates`, file)
const updateEmailTemplate = (id, file) => api.patch(`api/v1/email-templates/${id}`, file)
const deleteEmailTemplate = (id) => api.delete(`api/v1/email-templates/${id}`);

const enableCardPayments = (businessId) => api.patch(`api/v1/businesses/card-payments/${businessId}/toggle`);
const passingFeesOn = (businessId) => api.patch(`api/v1/businesses/fees-passed-on/${businessId}/toggle`);
const overrideMomoFees = (businessId, fees) => api.patch(`api/v1/businesses/momo-fees/${businessId}/override/${fees}`);
const overrideCardFees = (businessId, fees) => api.patch(`api/v1/businesses/card-fees/${businessId}/override/${fees}`);

const businessVerificationRequest = (businessId) => api.get(`api/v1/identity-verif-requests/count?businessId.equals=${businessId}&status.equals=PENDING`);
const verificationStartedRequest = (businessId) => api.patch(`api/v1/identity-verif-requests/${businessId}/auto-create`);

// ignite-jhipster-api-method-needle

// ------
// STEP 3
// ------
//
// Return back a collection of functions that we would consider our
// interface.  Most of the time it'll be just the list of all the
// methods in step 2.
//
// Notice we're not returning back the `api` created in step 1?  That's
// because it is scoped privately.  This is one way to create truly
// private scoped goodies in JavaScript.
//
export {
  // a list of the API functions from step 2

  setAuthToken,
  removeAuthToken,
  getAccount,
  getOneSettlement,
  getSettlement,
  getAllSettlements,
  createSettlement,
  updateSettlement,
  deleteSettlement,
  getSettlementCount,
  getFilterSettlements,
  getProviderWallets,
  verifyAccount,
  approveSettlement,
  getPaymentSources,
  reverseSettlement,

  getPaymentSchedule,
  getPaymentSchedules,
  getPaymentScheduleCount,
  getPaymentScheduleRange,
  getPaymentScheduleSummary,
  getPaymentScheduleProjection,
  createPaymentSchedule,
  updatePaymentSchedule,
  deletePaymentSchedule,
  notifyPaymentSchedule,
  getFilterPaymentSchedule,
  getFilterPaymentCount,
  externallyMarkPayment,

  getBusiness,
  getBusinesses,
  getBusinessesCount,
  createBusiness,
  updateBusiness,
  deleteBusiness,
  getCachedBusinesses,
  getEnterpriseCustomers,
  getEnterpriseCustomersCount,
  businessVerificationRequest,
  verificationStartedRequest,


  getPaymentTrackRange,
  getPayoutChannel,
  getPayoutChannels,
  createPayoutChannel,
  updatePayoutChannel,
  deletePayoutChannel,
  archivePayoutChannel,

  getSubscription,
  getAllSubscriptions,
  getCountSubscriptions,
  searchSubscriptions,
  dateFilterSubscriptions,
  searchSubscriptionCount,
  pauseSubscription,
  cancelSubscription,
  rescheduleSubscription,

  getPlanGroupsGraphedArchived,
  getPlanGroupsGraphedActive,

  getCredential,
  enableRequest,
  generateRequest,
  disableRequest,

  indexSubscribers,
  indexPayments,
  indexSignupRequests,
  indexCustomers,
  indexPaymentsSchedules,
  indexPlans,
  indexBusinessCustomers,
  indexActivities,
  indexAllSettlements,

  getCountry,
  getCountries,
  updateCountry,
  getIdentityDocuments,
  createIdentityDocuments,
  updateIdentityDocuments,
  disableIdentityDocuments,
  getIdentityDocument,
  getIdentityDocumentsCount,
  getZones,

  getCustomers,
  getCustomersCount,

  getPendingVerifications,
  getApprovedVerifications,
  getRejectedVerifications,
  getPendingVerificationsCount,
  getApprovedVerificationsCount,
  getRejectedVerificationsCount,

  getSystemBanks,
  getSystemBank,
  getSystemBanksCount,
  logoUpload,
  disableSystemBank,
  getProviderBanks,
  getProviders,
  getBankPerProvider,
  linkProviderToBank,
  unlinkProviderBank,
  disablePaystackOtp,
  confirmOtpDisable,

  pendingVerifications,
  pendingVerification,
  approveVerification,
  rejectVerification,
  getVerificationProofRequest,
  approvedVerificationDetail,
  getIdentityImage,


  getCustomerImports,
  uploadCustomerImports,
  applyImport,
  getEntriesList,

  getFilteredDlrs,
  getAllDLRs,
  getAllDLRsCount,
  getFilteredCountDLRs,

  getEmailTemplate,
  getFilteredNameEmailTemplate,
  createEmailTemplate,
  updateEmailTemplate,
  deleteEmailTemplate,

  enableCardPayments,
  passingFeesOn,
  overrideMomoFees,
  overrideCardFees,

};
