import { createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    emailTemplateRequest: [],
    filteredRequest: ["filter"],
    createEmailTemplateRequest: ['file'],
    updateEmailTemplateRequest: ['id', 'file'],
    deleteEmailTemplateRequest: ['id'],

    emailTemplateSuccess: ["emailTemplates"],
    filteredSuccess: ["nameTemplates"],
    createSuccess: ["emailTemplate"],
    updateSuccess: ["editedTemplate"],
    deleteSuccess: ["deletedTemplate"],

    emailTemplateFailure: ["error"],
    filteredFailure: ["error"],
    createFailure: ["error"],
    updateFailure: ["error"],
    deleteFailure: ["error"],

})

export const EmailTemplatesTypes = Types;
export default Creators