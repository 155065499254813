import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
    customersRequest: ["options"],
    customersCountRequest: [],
    customerImportsRequest: ["businessId", "options"],
    uploadRequest: ["businessId", "file"],
    applyUpload: ["id"],
    entriesRequest: ["importId"],

    customersSuccess: ["customers"],
    customersCountSuccess: ["counts"],
    customerImportsSuccess: ["customerImports"],
    uploadSuccess: ["document"],
    applyUploadSuccess: ["fileImported"],
    entriesSuccess: ["entriesList"],

    customerFailure: ["error"],
    customersCountFailure: ["error"],
    customerImportsFailure: ["error"],
    uploadFailure: ['error'],
    applyUploadFailure: ['error'],
    entriesFailure: ['error'],
})

export const CustomersTypes = Types;
export default Creators