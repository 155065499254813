import { createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";
import { CustomersTypes } from "./customers-actions";

export const INITIAL_STATE = Immutable({
    fetchingCustomers: false,
    fetchingCustomersCount: false,
    fetchingCustomerImports: false,
    uploading: false,
    applyingUpload: false,
    fetchingEntries: false,

    customers: null,
    counts: null,
    customerImports: null,
    documentUpload: null,
    documentUploadApplied: null,
    customerEntries: null,

    errorCustomers: null,
    errorCounts: null,
    errorCustomerImports: null,
    errorUploading: null,
    errorApplyingUpload: null,
    errorFetchingEntries: null,
})


export const customersRequest = (state) => {
    return { ...state, fetchingCustomers: true }
}
export const customersCountRequest = (state) => {
    return { ...state, fetchingCustomersCount: true }
}
export const customerImportsRequest = (state) => {
    return { ...state, fetchingCustomerImports: true, customerImports: null }
}
export const uploadRequest = (state) => {
    return { ...state, uploading: true, documentUpload: null }
}
export const applyUpload = (state) => {
    return { ...state, applyingUpload: true, documentUploadApplied: null }
}
export const entriesRequest = (state) => {
    return { ...state, fetchingEntries: true, customerEntries: null }
}

export const customersSuccess = (state, action) => {
    const { customers } = action;
    return { ...state, customers, errorCustomers: null }
}
export const customersCountSuccess = (state, action) => {
    const { counts } = action;
    return { ...state, counts, errorCustomers: null }
}
export const customerImportsSuccess = (state, action) => {
    const { customerImports } = action;
    return { ...state, fetchingCustomerImports: false, errorCustomerImports: null, customerImports: customerImports }
}

export const uploadSuccess = (state, action) => {
    const { document } = action;
    return { ...state, uploading: false, errorUploading: null, documentUpload: document }
}

export const applyUploadSuccess = (state, action) => {
    const { fileImported } = action
    return { ...state, applyingUpload: false, errorApplyingUpload: null, documentUploadApplied: fileImported }
}

export const entriesSuccess = (state, action) => {
    const { entriesList } = action
    return { ...state, fetchingEntries: false, errorFetchingEntries: null, customerEntries: entriesList }
}

export const customersFailure = (state, action) => {
    const { error } = action;
    return { ...state, errorCustomers: error, fetchingCustomers: false }
}

export const customersCountFailure = (state, action) => {
    const { error } = action;
    return { ...state, errorCustomers: error, fetchingCustomers: false }
}
export const customerImportsFailure = (state, action) => {
    const { error } = action;
    return { ...state, errorCustomerImports: error, fetchingCustomerImports: false }
}
export const uploadFailure = (state, action) => {
    const { error } = action;
    return { ...state, errorUploading: error, uploading: false }
}

export const applyUploadFailure = (state, action) => {
    const { error } = action;
    return { ...state, errorApplyingUpload: error, applyingUpload: false }
}

export const entriesFailure = (state, action) => {
    const { error } = action;
    return { ...state, errorFetchingEntries: error, fetchingEntries: false }
}

export const reducer = createReducer(INITIAL_STATE, {
    [CustomersTypes.CUSTOMERS_REQUEST]: customersRequest,
    [CustomersTypes.CUSTOMERS_COUNT_REQUEST]: customersCountRequest,
    [CustomersTypes.CUSTOMER_IMPORTS_REQUEST]: customerImportsRequest,
    [CustomersTypes.UPLOAD_REQUEST]: uploadRequest,
    [CustomersTypes.APPLY_UPLOAD]: applyUpload,
    [CustomersTypes.ENTRIES_REQUEST]: entriesRequest,

    [CustomersTypes.CUSTOMERS_SUCCESS]: customersSuccess,
    [CustomersTypes.CUSTOMERS_COUNT_SUCCESS]: customersCountSuccess,
    [CustomersTypes.CUSTOMER_IMPORTS_SUCCESS]: customerImportsSuccess,
    [CustomersTypes.UPLOAD_SUCCESS]: uploadSuccess,
    [CustomersTypes.APPLY_UPLOAD_SUCCESS]: applyUploadSuccess,
    [CustomersTypes.ENTRIES_SUCCESS]: entriesSuccess,

    [CustomersTypes.CUSTOMER_FAILURE]: customersFailure,
    [CustomersTypes.CUSTOMERS_COUNT_FAILURE]: customersCountFailure,
    [CustomersTypes.CUSTOMER_IMPORTS_FAILURE]: customerImportsFailure,
    [CustomersTypes.UPLOAD_FAILURE]: uploadFailure,
    [CustomersTypes.APPLY_UPLOAD_FAILURE]: applyUploadFailure,
    [CustomersTypes.ENTRIES_FAILURE]: entriesFailure,
})