import { createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";
import { AuthorizationTypes } from "./authorization-actions";

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    status: null
});

/* ------------- Reducers ------------- */

// successful authorization status determined
export const setStatus = (state, action) => {
    const { status } = action;
    return { ...state, status };
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {

    [AuthorizationTypes.IS_AUTHORIZED_USER]: setStatus,

});
