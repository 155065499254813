import { call, put } from "redux-saga/effects";
import CustomersActions from "./customers-actions"
import * as api from "../../services/api"

export function* getCustomers(action) {
    const { options } = action
    const response = yield call(api.getCustomers, options)
    //success?
    if (response.ok) {
        yield put(CustomersActions.customersSuccess(response.data));
    } else {
        yield put(CustomersActions.customersFailure(response.data))
    }
}

export function* getCustomersCount() {
    const response = yield call(api.getCustomersCount)
    //success?
    if (response.ok) {
        yield put(CustomersActions.customersCountSuccess(response.data));
    } else {
        yield put(CustomersActions.customersCountFailure(response.data))
    }
}

export function* getCustomerImportsList(action) {
    const { businessId, options } = action
    const response = yield call(api.getCustomerImports, businessId, options)
    //success?
    if (response.ok) {
        yield put(CustomersActions.customerImportsSuccess(response.data));
    } else {
        yield put(CustomersActions.customerImportsFailure(response.data))
    }
}

export function* uploadDocument(action) {
    const { businessId, file } = action
    const response = yield call(api.uploadCustomerImports, businessId, file)
    if (response.ok) {
        yield put(CustomersActions.uploadSuccess(response.data));
    } else {
        yield put(CustomersActions.uploadFailure(response.data))
    }
}

export function* applyCustomerImport(action) {
    const { id } = action
    const response = yield call(api.applyImport, id)
    if (response.ok) {
        yield put(CustomersActions.applyUploadSuccess(response.data));
    } else {
        yield put(CustomersActions.applyUploadFailure(response.data))
    }
}

export function* getListOfCustomerEntries(action) {
    const { importId } = action
    const response = yield call(api.getEntriesList, importId)
    if (response.ok) {
        yield put(CustomersActions.entriesSuccess(response.data));
    } else {
        yield put(CustomersActions.entriesFailure(response.data))
    }
}
