import { call, put } from 'redux-saga/effects'
import * as api from "../../services/api";
import VerificationListActions from "./verifications-actions"


export function* getPendingVerificationList(action) {
    const { options } = action
    const response = yield call(api.getPendingVerifications, options)
    if (response.ok) {
        yield put(VerificationListActions.pendingVerificationListSuccess(response.data))
    } else {
        yield put(VerificationListActions.pendingVerificationListFailure(response.data))
    }

}
export function* getApprovedVerificationList(action) {
    const { options } = action
    const response = yield call(api.getApprovedVerifications, options)
    if (response.ok) {
        yield put(VerificationListActions.approvedVerificationListSuccess(response.data))
    } else {
        yield put(VerificationListActions.approvedVerificationListFailure(response.data))
    }

}
export function* getRejectedVerificationList(action) {
    const { options } = action

    const response = yield call(api.getRejectedVerifications, options)
    if (response.ok) {
        yield put(VerificationListActions.rejectedVerificationListSuccess(response.data))
    } else {
        yield put(VerificationListActions.rejectedVerificationListFailure(response.data))
    }

}
export function* getPendingVerificationCount() {

    const response = yield call(api.getPendingVerificationsCount)
    if (response.ok) {
        yield put(VerificationListActions.pendingVerificationCountSuccess(response.data))
    } else {
        yield put(VerificationListActions.pendingVerificationCountFailure(response.data))
    }

}
export function* getApprovedVerificationCount() {

    const response = yield call(api.getApprovedVerificationsCount)
    if (response.ok) {
        yield put(VerificationListActions.approvedVerificationCountSuccess(response.data))
    } else {
        yield put(VerificationListActions.approvedverificationCountFailure(response.data))
    }

}
export function* getRejectedVerificationCount() {

    const response = yield call(api.getRejectedVerificationsCount)
    if (response.ok) {
        yield put(VerificationListActions.rejectedVerificationCountSuccess(response.data))
    } else {
        yield put(VerificationListActions.rejectedVerificationCountFailure(response.data))
    }

}

export function* getVerificationList  ()  {
    const response = yield call(api.pendingVerifications)

    if(response.ok){
        yield put(VerificationListActions.verificationListSuccess(response.data))
    }
    else{
        yield put(VerificationListActions.verificationListFailure(response.data))
    }
    
}

export function* getVerification  (action)  {
    const {id} = action
    const response = yield call(api.pendingVerification,id)

    if(response.ok){
        yield put(VerificationListActions.verificationSuccess(response.data))
    }
    else{
        yield put(VerificationListActions.verificationFailure(response.data))
    }
    
}

export function* approveVerification  (action)  {
    const {id,username} = action
    const response = yield call(api.approveVerification, id, username)
    console.log(response)

    if(response.ok){
        yield put(VerificationListActions.verificationApproveSuccess(response.data))
    }
    else{
        yield put(VerificationListActions.verificationApproveFailure(response.data))
    }
    
}

export function* rejectVerification  (action)  {
    const {id,username, message} = action
    const response = yield call(api.rejectVerification, id, username, message)

    if(response.ok){
        console.log(response, response.status);
        yield put(VerificationListActions.verificationRejectSuccess(response.data))
    }
    else{
        yield put(VerificationListActions.verificationRejectFailure(response.data))
    }
    
}
export function* getVerificationProof  (action)  {
    const {id} = action
    const response = yield call(api.getVerificationProofRequest, id)

    if(response.ok){
        yield put(VerificationListActions.proofSuccess(response.data))
    }
    else{
        yield put(VerificationListActions.proofFailure(response.data))
    }
    
}

export function* getImage  (action)  {
    const {id} = action
    const response = yield call(api.getIdentityImage, id)

    if(response.ok){
        console.log(response.data, 'saga');
        yield put(VerificationListActions.imageSuccess(response.data))
    }
    else{
        yield put(VerificationListActions.imageFailure(response.data))
    }
    
}

