import { createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";
import { EmailTemplatesTypes } from "./email-template-actions";

export const INITIAL_STATE = Immutable({
    fetchingEmailTemplates: false,
    fetchingFilteredTemplates: false,
    creating: false,
    updating: false,
    deleting: false,

    emailTemplates: null,
    filteredTemplates: null,
    createdTemplate: null,
    updateTemplate: null,
    deletedTemplate: null,

    errorEmailTemplates: null,
    errorFilteredTemplates: null,
    errorCreating: null,
    errorUpdating: null,
    errorDeleting: null,
})

export const emailTemplatesRequest = (state) => {
    return { ...state, fetchingEmailTemplates: true, emailTemplates:null}
}

export const createRequest = (state) => {
    return { ...state, creating: true,  }
}
export const updateRequest = (state) => {
    return { ...state, updating: true,  }
}

export const deleteRequest = (state) => {
    return { ...state, deleting: true,  }
}

export const filteredRequest = (state) => {
    return { ...state, fetchingFilteredTemplates: true,  filteredTemplates:null}
}

export const emailTemplateSuccess = (state, action) => {
    const { emailTemplates } = action;
    return { ...state, emailTemplates: emailTemplates, errorEmailTemplates: null, fetchingEmailTemplates: false }
}

export const createSuccess = (state, action) => {
    const { emailTemplate } = action;
    return { ...state, createdTemplate: emailTemplate, creating:false, errorCreating: null }
}

export const updateSuccess = (state, action) => {
    const { editedTemplate } = action;
    return { ...state, updateTemplate: editedTemplate, updating:false, errorUpdating: null }
}

export const deleteSuccess = (state, action) => {
    const { deletedTemplate } = action;
    return { ...state, deletedTemplate: deletedTemplate, deleting:false, errorDeleting: null }
}

export const  filteredSuccess = (state, action) => {
    const { nameTemplates } = action;
    return { ...state, filteredTemplates:nameTemplates, fetchingFilteredTemplates: false }
}

export const emailTemplatesFailure = (state, action) => {
    const { error } = action;
    return { ...state, errorEmailTemplates: error, fetchingEmailTemplates: false }
}

export const createFailure = (state, action) => {
    const { error } = action;
    return { ...state, errorCreating: error, creating: false, createdTemplate: null }
}
export const updateFailure = (state, action) => {
    const { error } = action;
    return { ...state, errorUpdating: error, updating: false, updateTemplate: null }
}

export const deleteFailure = (state, action) => {
    const { error } = action;
    return { ...state, errorDeleting: error, deleting: false, deletedTemplate: null }
}

export const filteredFailure = (state, action) => {
    const { error } = action;
    return { ...state, fetchingFilteredTemplates: false, errorFilteredTemplates: error }
}


export const reducer = createReducer(INITIAL_STATE, {
    [EmailTemplatesTypes.EMAIL_TEMPLATE_REQUEST]: emailTemplatesRequest,
    [EmailTemplatesTypes.CREATE_EMAIL_TEMPLATE_REQUEST]: createRequest,
    [EmailTemplatesTypes.UPDATE_EMAIL_TEMPLATE_REQUEST]: updateRequest,
    [EmailTemplatesTypes.DELETE_EMAIL_TEMPLATE_REQUEST]: deleteRequest,
    [EmailTemplatesTypes.FILTERED_REQUEST]: filteredRequest,
   
    [EmailTemplatesTypes.EMAIL_TEMPLATE_SUCCESS]: emailTemplateSuccess,
    [EmailTemplatesTypes.CREATE_SUCCESS]: createSuccess,
    [EmailTemplatesTypes.UPDATE_SUCCESS]: updateSuccess,
    [EmailTemplatesTypes.DELETE_SUCCESS]: deleteSuccess,
    [EmailTemplatesTypes.FILTERED_SUCCESS]: filteredSuccess,
   
    [EmailTemplatesTypes.EMAIL_TEMPLATE_FAILURE]: emailTemplatesFailure,
    [EmailTemplatesTypes.CREATE_FAILURE]: createFailure,
    [EmailTemplatesTypes.UPDATE_FAILURE]: updateFailure,
    [EmailTemplatesTypes.DELETE_FAILURE]: deleteFailure,
    [EmailTemplatesTypes.FILTERED_FAILURE]: filteredFailure, 
})