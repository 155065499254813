import { createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";
import { DLRsTypes } from "./dlrs-actions";

export const INITIAL_STATE = Immutable({
    fetchingFilteredDlrs: false,
    fetchingAllDLRs: false,
    fetchingFilteredCountDlrs: false,
    fetchingAllCountDlrs: false,

    filteredDlrs: null,
    allDLRs: null,
    filteredCountDlrs: null,
    allCountDlrs: null,


    errorFilteredDlrs: null,
    errorAllDLRs: null,
    errorFilteredCountDlrs: null,
    errorAllCountDlrs: null
})

export const filteredDlrsRequest = (state) => {
    return { ...state, fetchingFilteredDlrs: true, filteredDlrs: null }
}
export const dlrsAllRequest = (state) => {
    return { ...state, fetchingAllDLRs: true }
}
export const allDlrsCountRequest = (state) => {
    return { ...state, fetchingDLRsCount: true, dlrsCount: null, }
}
export const filteredCountDlrsRequest = (state) => {
    return { ...state, fetchingFilteredCountDlrs: true, filteredCountDlrs: null, }
}


export const  filteredDlrsSuccess = (state, action) => {
    const { dlr } = action;
    return { ...state, filteredDlrs:dlr, fetchingFilteredDlrs: false }
}
export const dlrsAllSuccess = (state, action) => {
    const { dlrs } = action;
    return { ...state, allDLRs: dlrs, errorAllDLRs: null }
}
export const allDlrsCountSuccess = (state, action) => {
    const { allDlrsCount } = action;
    return { ...state, allCountDlrs: allDlrsCount, errorDLRsCount: null }
}
export const filteredCountDlrsSuccess = (state, action) => {
    const { countDLRs } = action;
    return { ...state, filteredCountDlrs: countDLRs, errorFilteredCountDlrs: null }
}


export const filteredDlrsFailure = (state, action) => {
    const { error } = action;
    return { ...state, fetchingFilteredDlrs: false, errorFilteredDlrs: error }
}
export const dlrsAllFailure = (state, action) => {
    const { error } = action;
    return { ...state, errorAllDLRs: error, fetchingAllDLRs: false }
}
export const allDlrsCountFailure = (state, action) => {
    const { error } = action;
    return { ...state, errorDLRsCount: error, fetchingDLRsCount: false, dlrsCount: null }
}
export const filteredCountDlrsFailure = (state, action) => {
    const { error } = action;
    return { ...state, errorFilteredCountDlrs: error, fetchingFilteredCountDlrs: false, filteredCountDlrs: null }
}


export const reducer = createReducer(INITIAL_STATE, {
    [DLRsTypes.FILTERED_DLRS_REQUEST]: filteredDlrsRequest,
    [DLRsTypes.DLRS_ALL_REQUEST]: dlrsAllRequest,
    [DLRsTypes.ALL_DLRS_COUNT_REQUEST]: allDlrsCountRequest,
    [DLRsTypes.FILTERED_COUNT_DLRS_REQUEST]: filteredCountDlrsRequest,

    [DLRsTypes.FILTERED_DLRS_SUCCESS]: filteredDlrsSuccess,
    [DLRsTypes.DLRS_ALL_SUCCESS]: dlrsAllSuccess,
    [DLRsTypes.ALL_DLRS_COUNT_SUCCESS]: allDlrsCountSuccess,
    [DLRsTypes.FILTERED_COUNT_DLRS_SUCCESS]: filteredCountDlrsSuccess,

    [DLRsTypes.FILTERED_DLRS_FAILURE]: filteredDlrsFailure,
    [DLRsTypes.DLRS_ALL_FAILURE]: dlrsAllFailure,
    [DLRsTypes.ALL_DLRS_COUNT_FAILURE]: allDlrsCountFailure,
    [DLRsTypes.FILTERED_COUNT_DLRS_FAILURE]: filteredCountDlrsFailure,
})