import { call, put } from 'redux-saga/effects'
import * as api from "../../services/api";
import SystemBanksActions from "./system-banks-actions"


export function* getSystemBankList(action) {
    const { countryId, options } = action;
    const response = yield call(api.getSystemBanks, countryId, options)
    if (response.ok) {
        yield put(SystemBanksActions.systemBankListSuccess(response.data))
    } else {
        yield put(SystemBanksActions.systemBankListFailure(response.data))
    }
}
export function* getSystemBank(action) {
    const { Id } = action;
    const response = yield call(api.getSystemBank, Id)
    if (response.ok) {
        yield put(SystemBanksActions.systemBankSuccess(response.data))
    } else {
        yield put(SystemBanksActions.systemBankFailure(response.data))
    }
}

export function* getSystemBanksCount(action) {
    const { countryId } = action;
    const response = yield call(api.getSystemBanksCount, countryId);
    if (response.ok) {
        yield put(SystemBanksActions.systemBanksCountSuccess(response.data));
    } else {
        yield put(SystemBanksActions.systemBanksCountFailure(response.data))
    }
}

export function* logoUpload(action) {
    const { bankId, file } = action;
    const response = yield call(api.logoUpload, bankId, file);
    if (response.ok) {
        yield put(SystemBanksActions.logoSuccess(response.data));
    } else {
        yield put(SystemBanksActions.logoFailure(response.data))
    }
}

export function* disableSystemBank(action) {
    const { bankId } = action;
    const response = yield call(api.disableSystemBank, bankId);
    if (response.ok) {
        yield put(SystemBanksActions.disableSystemBankSuccess(response.data));
    } else {
        yield put(SystemBanksActions.disableSystemBankFailure(response.data))
    }
}

export function* getListOfProviderBanks(action) {
    const { systemBankId } = action
    const response = yield call(api.getProviderBanks, systemBankId);
    if (response.ok) {
        yield put(SystemBanksActions.providerBanksSuccess(response.data));
    } else {
        yield put(SystemBanksActions.providerBanksFailure(response.data));
    }
}

export function* getListOfProviders() {
    const response = yield call(api.getProviders);
    if (response.ok) {
        yield put(SystemBanksActions.providersSuccess(response.data));
    } else {
        yield put(SystemBanksActions.providersFailure(response.data));
    }
}

export function* getListOfBanksPerProvider(action) {
    const { paymentProvider } = action
    const response = yield call(api.getBankPerProvider, paymentProvider);
    if (response.ok) {
        yield put(SystemBanksActions.bankPerProviderSuccess(response.data));
    } else {
        yield put(SystemBanksActions.bankPerProviderFailure(response.data));
    }
}

export function* linkProviderToBank(action) {
    const { systemBankId, providerBankId } = action
    const response = yield call(api.linkProviderToBank, systemBankId, providerBankId)
    if (response.ok) {
        yield put(SystemBanksActions.linkSuccess(response.data));
    } else {
        yield put(SystemBanksActions.linkFailure(response.data));
    }
}

export function* unlinkProviderToBank(action) {
    const { systemBankId, providerBankId } = action
    const response = yield call(api.unlinkProviderBank, systemBankId, providerBankId)
    if (response.ok) {
        yield put(SystemBanksActions.unlinkSuccess(response.data));
    } else {
        yield put(SystemBanksActions.unlinkFailure(response.data));
    }
}