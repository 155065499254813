import React from 'react';


const Footer = () => {
    return (
        <footer className="footer text-center">
            All Rights Reserved by Materialpro admin. Designed and Developed by{' '}
            <a href="https://wrappixel.com">WrapPixel</a>.
        </footer>
    );
}
export default Footer;
