import React from 'react'
import "../../assets/scss/all/loader.scss";

function Loading() {

    return (
        <div className='fallback-loader'>
            <div className="loaders">
                <h1>Loading</h1>
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
                <div className="bar4"></div>
                <div className="bar5"></div>
                <div className="bar6"></div>
            </div>
        </div>
    )
}

export default Loading
