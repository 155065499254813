import React, { Suspense } from "react";
import ReactDOM from "react-dom/client"
import { Auth0Provider } from "@auth0/auth0-react";
import Spinner from "./views/spinner/Spinner";
import { ErrorBoundary } from 'react-error-boundary';
import App from './app';
import { ErrorFallback } from "./components/error-handlers/ErrorFallback";
import "./data";
import './i18n';
import "./assets/scss/style.css";
import KeycloakProviderWrapper from "./KeycloakProvider";
import { Router } from "react-router-dom";

const rootElement = document.getElementById('root');

ReactDOM.createRoot(rootElement).render(
  <Suspense fallback={<Spinner />}>
   <KeycloakProviderWrapper>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <App />
      </ErrorBoundary>
      </KeycloakProviderWrapper>
  </Suspense>,
);

if (module.hot) {
  module.hot.accept();
}