import React, { useEffect, useCallback } from 'react';
import { setAuthToken } from "../services/api"
import { useNavigate } from "react-router-dom";
import Loading from '../views/ui-components/Loading';
import { useKeycloak } from '@react-keycloak/web';


const ImplicitCallback = () => {
    const { keycloak,} = useKeycloak();
    const navigate = useNavigate();
    const handleToken = useCallback(async () => {
        try {
          await keycloak.updateToken(60)
            .then(() => {
              const token = keycloak.token;
              setAuthToken(token);
            })
            .then(() => {        
                navigate('/settlements', { replace: true });
           
            })
          
        } catch (e) {
          keycloak.logout({
            redirectUri: process.env.REACT_APP_AUTH_LOGOUTURL, // Redirect to localhost:3000 after logout
            });
        }
      }, [keycloak, navigate, ]);

      useEffect(() => {
        handleToken();
      }, [handleToken]);
  
    return <Loading />;
  };
  
  export default ImplicitCallback;
