import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "reactstrap";
import { useTranslation } from 'react-i18next';
import Loading from '../ui-components/Loading';
import { useNavigate } from "react-router-dom";
import AuthorizationActions from "../../redux/authorization/authorization-actions";
import { useDispatch } from "react-redux";
import { useKeycloak } from '@react-keycloak/web';

import img2 from "../../assets/images/big/auth-bg.jpg";

const sidebarBackground = {
    backgroundImage: "url(" + img2 + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
};

const LoginScreen = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const setAuthorizationStatus = (status) => dispatch(AuthorizationActions.isAuthorizedUser(status));

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
    const [isAuthPending, setIsAuthPending] = useState(false);
    const [userInfo, setUserInfo] = useState(null);

    const { keycloak, initialized } = useKeycloak();

    const authText = t("authentication.auth_text");

    useEffect(() => {
        if (!initialized) {
            setIsAuthPending(true);
        } else {
            setIsAuthPending(false);
            if (keycloak.authenticated) {
                setIsUserAuthenticated(true);
                setUserInfo(keycloak.tokenParsed);
            } else {
                setIsUserAuthenticated(false);
                setUserInfo(null);
            }
        }
    }, [keycloak, initialized]);

    if (isAuthPending) {
        return <div className="align-items-center d-flex">{authText}</div>;
    }

    if (!isUserAuthenticated) {
        return (
            <div
            className="auth-wrapper  align-items-center d-flex"
            style={sidebarBackground}
        >
            {/*--------------------------------------------------------------------------------*/}
            {/*LoginScreen Card*/}
            {/*--------------------------------------------------------------------------------*/}
            <div className="container">
                <div>
                    <Row className="no-gutters justify-content-center">
                        <Col md="6" lg="4" className="bg-dark text-white">
                            <div className="p-4">
                                <h2 className="display-5">
                                    {t("authentication.hi")}
                                    <br />
                                    <span className="text-cyan font-bold">{t("authentication.admin")}</span>
                                </h2>
                                <p className="op-5 mt-4">
                                    {t("authentication.desciption")}
                                </p>
                                <p className="mt-5">{t("authentication.tagline")}</p>
                            </div>
                        </Col>
                        <Col md="6" lg="4" className="bg-white">
                            <div className="p-4">
                                <h3 className="font-medium pb-5">{t("authentication.signin_text")}</h3>
                                <Row className="mb-3 mt-5">
                                    <Col xs="12">
                                        <Button color="primary" onClick={() => keycloak.login()} size="lg" block >
                                            {t("authentication.log_in")}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
    }

    if (isUserAuthenticated) {
        // Check for admin access here
        const roles = keycloak.tokenParsed?.realm_access?.roles || [];
        const superUserRole = roles?.includes('ADMIN');
        if(superUserRole){
            setAuthorizationStatus(true);
            navigate("/implicit/callback");
        }
        else{
            setAuthorizationStatus(false);
            navigate("/authentication/access-denied");
        }
    }
    

    return <Loading />;
};

export default LoginScreen;