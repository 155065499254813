 
 import { call, put } from "redux-saga/effects";
 import EmailTemplateActions from "./email-template-actions"
 import * as api from "../../services/api"
 
 export function* getEmailTemplates(action) {
    //  const { options } = action
     const response = yield call(api.getEmailTemplate,)
     //success?
     if (response.ok) {
         yield put(EmailTemplateActions.emailTemplateSuccess(response.data));
     } else {
         yield put(EmailTemplateActions.emailTemplateFailure(response.data))
     }
 }
 
 export function* getFilteredName(action) {
     const { filter } = action
     const response = yield call(api.getFilteredNameEmailTemplate, filter)
     //success?
     if (response.ok) {
         yield put(EmailTemplateActions.filteredSuccess(response.data));
     } else {
         yield put(EmailTemplateActions.filteredFailure(response.data))
     }
 }

 export function* createTemplate(action) {
    const {file} = action
     const response = yield call(api.createEmailTemplate, file)
     //success?
     if (response.ok) {
         yield put(EmailTemplateActions.createSuccess(response.data));
     } else {
         yield put(EmailTemplateActions.createFailure(response.data))
     }
 }

 export function* updateTemplate(action) {
    const {id, file} = action
     const response = yield call(api.updateEmailTemplate, id, file)
     //success?
     if (response.ok) {
         yield put(EmailTemplateActions.updateSuccess(response.data));
     } else {
         yield put(EmailTemplateActions.updateFailure(response.data))
     }
 }

 export function* deleteTemplate(action) {
    const {id} = action
     const response = yield call(api.deleteEmailTemplate, id)
     //success?
     if (response.ok) {
         yield put(EmailTemplateActions.deleteSuccess(response.data));
     } else {
         yield put(EmailTemplateActions.deleteFailure(response.data))
     }
 }
 
 