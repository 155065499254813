import { createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";
import { AccountTypes } from "./account-action";
/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    fetchingAccount: null,
    userAccount: null,
    errorFetchingAccount: null
});

/* ------------- Reducers ------------- */

//request data from api 
export const accountRequest = (state) => {
    return { ...state, fetchingAccount: true, userAccount: null };
};

//successful data from api 
export const accountSuccess = (state, action) => {
    const { userAccount } = action
    return { ...state, fetchingAccount: false, userAccount, errorFetchingAccount: null };
};

//something went wrong fetching data from api 
export const accountFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingAccount: false, userAccount: null, errorFetchingAccount: error };
};
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {

    [AccountTypes.ACCOUNT_REQUEST]: accountRequest,
    [AccountTypes.ACCOUNT_SUCCESS]: accountSuccess,
    [AccountTypes.ACCOUNT_FAILURE]: accountFailure,

});
