import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { SettlementTypes } from './settlement-actions'

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    fetchingOneSettlement: null,
    fetchingBusiness: null,
    fetchingAll: null,
    fetchingCount: null,
    fetchingFiltered: null,
    updating: null,
    deleting: null,
    fetchingProviderWallets: null,
    verifyingAccount: null,
    disablingPaystack: null,
    confirmingOtpDisale: null,
    approvingSettlement: false,
    fetchingPaymentSources: null,
    reversingSettlement: null,

    count: null,
    filtered: null,
    settlementRequest: null,
    settlement: null,
    settlements: null,
    providerWallets: null,
    verifySuccess: null,
    paystackDisabled: null,
    paystackOtpDisabled: null,
    approvedSettlement: null,
    paymentSources: null,
    reversedSettlements: null,

    errorOne: null,
    errorOneSettlement: null,
    errorAll: null,
    errorCount: null,
    errorFiltering: null,
    errorUpdating: null,
    errorDeleting: null,
    errorFetchingProviderWallet: null,
    errorVerifyingAccount: null,
    errorDisablingPaystack: null,
    errorConfirmingOtpDisable: null,
    errorApprovingSettlement: null,
    errorfetchingPaymentSources: null,
    errorReversingSettlement: null
})

/* ------------- Reducers ------------- */
// request the data from an api
export const oneRequest = (state) => { return { ...state, fetchingOneSettlement: true, settlementRequest: null } }

// request the data from an api
export const businessRequest = (state) => { return { ...state, fetchingBusiness: true, settlement: null } }

// request the data from an api
export const allRequest = (state) => { return { ...state, fetchingAll: true, settlements: null, errorAll: null } }

// request the data from an api
export const countRequest = (state) => { return { ...state, fetchingCount: true, count: null } }

// request the data from an api
export const filterRequest = (state) => { return { ...state, fetchingFiltered: true, filtered: null } }
// request to update from an api
export const updateRequest = (state) => { return { ...state, updating: true } }

// request to delete from an api
export const deleteRequest = (state) => { return { ...state, deleting: true } }

// request to fetch provider's wallets from an api
export const providerWalletRequest = (state) => { return { ...state, fetchingProviderWallets: true } }

// request to verify a bank account has provided for payment 
export const verifyAccountRequest = (state) => { return { ...state, verifyingAccount: true, verifySuccess: null, errorVerifyingAccount: null } }

// request to disable paystack otp
export const disablePaystackRequest = (state) => { return { ...state, disablingPaystack: true } }

// request to confirm disabling an otp
export const confirmDisableRequest = (state) => { return { ...state, confirmingOtpDisale: true } }

// request to approve a pending settlement from an api
export const approveSettlementRequest = (state) => { return { ...state, approvingSettlement: true, errorApprovingSettlement: null } }

// request to fetch payment sources from an api 
export const paymentSourcesRequest = (state) => { return { ...state, fetchingPaymentSources: true } }

// request to reverse a settlement request from an api
export const reverseSettlementRequest = (state) => { return { ...state, reversingSettlement: true, reversedSettlements: null, errorReversingSettlement: null } }

// successful api lookup for one entity
export const oneSuccess = (state, action) => {
    const { settlement } = action
    return { ...state, fetchingOneSettlement: false, errorOneSettlement: null, settlementRequest: settlement }
}
// successful api lookup for one entity
export const success = (state, action) => {
    const { settlement } = action
    return { ...state, fetchingBusiness: false, errorOne: null, settlement }
}
// successful api lookup for all entities
export const allSuccess = (state, action) => {
    const { settlements } = action
    return { ...state, fetchingAll: false, errorAll: null, settlements }
}
// successful api lookup for all entities
export const countSuccess = (state, action) => {
    const { count } = action
    return { ...state, fetchingCount: false, errorCount: null, count }
}
// successful api lookup for all entities
export const filterSuccess = (state, action) => {
    const { filtered } = action
    return { ...state, fetchingFiltered: false, errorFiltering: null, filtered }
}
// successful api update
export const updateSuccess = (state, action) => {
    const { settlement } = action
    return { ...state, updating: false, errorUpdating: null, settlement }
}
// successful api delete
export const deleteSuccess = (state) => {
    return { ...state, deleting: false, errorDeleting: null, settlement: null }
}

// successful api fetch of providers wallets
export const providerWalletSuccess = (state, action) => {
    const { wallets } = action
    return { ...state, fetchingProviderWallets: false, errorFetchingProviderWallet: null, providerWallets: wallets }
}

// successful api verify
export const verifyAccountSuccess = (state, action) => {
    const { details } = action
    return { ...state, verifyingAccount: false, verifySuccess: details }
}

// successful disabling paystack otp
export const disablePaystackSuccess = (state, action) => {
    const { disabledOtp } = action
    return { ...state, disablingPaystack: false, paystackDisabled: disabledOtp }
}

// successful confirming disabling of otp
export const confirmDisableSuccess = (state) => {
    return { ...state, confirmingOtpDisale: false, errorConfirmingOtpDisable: false }
}

// successful api approve of pending settlement 
export const approveSettlementSuccess = (state, action) => {
    const { data } = action
    return { ...state, approvingSettlement: false, errorApprovingSettlement: null, approvedSettlement: data }
}

// successful api fetch of payment sources
export const paymentSourcesSuccess = (state, action) => {
    const { paymentSources } = action
    return { ...state, fetchingPaymentSources: false, errorfetchingPaymentSources: null, paymentSources }
}

// successful api reversing  of settlement requests
export const reverseSettlementSuccess = (state, action) => {
    const { reversedSettlements } = action
    return { ...state, reversingSettlement: false, errorReversingSettlement: null, reversedSettlements }
}

// Something went wrong fetching one entity.
export const oneFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingOneSettlement: false, settlementRequest: null, errorOneSettlement: error }
}

// Something went wrong fetching one entity.
export const failure = (state, action) => {
    const { error } = action
    return { ...state, fetchingBusiness: false, settlement: null, errorOne: error }
}
// Something went wrong fetching all entities.
export const allFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingAll: false, settlements: null, errorAll: error }
}
// Something went wrong fetching all entities.
export const countFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingCount: false, count: null, errorCount: error }
}
// Something went wrong fetching all entities.
export const filterFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingFiltered: false, filtered: null, errorFiltering: error }
}
// Something went wrong updating.
export const updateFailure = (state, action) => {
    const { error } = action
    return { ...state, updating: false, settlement: state.settlement, errorUpdating: error }
}
// Something went wrong deleting.
export const deleteFailure = (state, action) => {
    const { error } = action
    return { ...state, deleting: false, settlement: state.settlement, errorDeleting: error }
}

// Something went wrong fetching provider wallets.
export const providerWalletFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingProviderWallets: false, providerWallets: false, errorFetchingProviderWallet: error }
}

// Something went wrong verifying account
export const verifyAccountFailure = (state, action) => {
    const { error } = action
    return { ...state, verifyingAccount: false, verifySuccess: null, errorVerifyingAccount: error }
}

// something went wrong disabling paystack otp
export const disablePaystackFailure = (state, action) => {
    const { error } = action
    return { ...state, disablingPaystack: false, paystackDisabled: null, errorDisablingPaystack: error }
}

// something went wrong confirming disabling
export const confirmDisableFailure = (state, action) => {
    const { error } = action
    return { ...state, confirmingOtpDisale: false, paystackOtpDisabled: false, errorConfirmingOtpDisable: error }
}

// something went wrong approving pending settlements
export const approveSettlementFailure = (state, action) => {
    const { error } = action
    return { ...state, approvingSettlement: false, approvedSettlement: false, errorApprovingSettlement: error }
}

// something went wrong fetching payment sources
export const paymentSourcesFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingPaymentSources: false, paymentSources: false, errorfetchingPaymentSources: error }
}

// something went wrong reversing  of settlement requests
export const reverseSettlementFailure = (state, action) => {
    const { error } = action
    return { ...state, reversingSettlement: false, errorReversingSettlement: error, reversedSettlements: null }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [SettlementTypes.SETTLEMENT_REQUEST]: oneRequest,
    [SettlementTypes.SETTLEMENT_BUSINESS_REQUEST]: businessRequest,
    [SettlementTypes.SETTLEMENT_ALL_REQUEST]: allRequest,
    [SettlementTypes.SETTLEMENT_COUNT_REQUEST]: countRequest,
    [SettlementTypes.SETTLEMENT_FILTER_REQUEST]: filterRequest,
    [SettlementTypes.SETTLEMENT_UPDATE_REQUEST]: updateRequest,
    [SettlementTypes.SETTLEMENT_DELETE_REQUEST]: deleteRequest,
    [SettlementTypes.PROVIDER_WALLET_REQUEST]: providerWalletRequest,
    [SettlementTypes.VERIFY_ACCOUNT_REQUEST]: verifyAccountRequest,
    [SettlementTypes.DISABLE_PAYSTACK_REQUEST]: disablePaystackRequest,
    [SettlementTypes.CONFIRM_DISABLE_REQUEST]: confirmDisableRequest,
    [SettlementTypes.APPROVE_SETTLEMENT_REQUEST]: approveSettlementRequest,
    [SettlementTypes.PAYMENT_SOURCES_REQUEST]: paymentSourcesRequest,
    [SettlementTypes.REVERSE_SETTLEMENT_REQUEST]: reverseSettlementRequest,

    [SettlementTypes.SETTLEMENT_SUCCESS]: oneSuccess,
    [SettlementTypes.SETTLEMENT_BUSINESS_SUCCESS]: success,
    [SettlementTypes.SETTLEMENT_ALL_SUCCESS]: allSuccess,
    [SettlementTypes.SETTLEMENT_COUNT_SUCCESS]: countSuccess,
    [SettlementTypes.SETTLEMENT_FILTER_SUCCESS]: filterSuccess,
    [SettlementTypes.SETTLEMENT_UPDATE_SUCCESS]: updateSuccess,
    [SettlementTypes.SETTLEMENT_DELETE_SUCCESS]: deleteSuccess,
    [SettlementTypes.PROVIDER_WALLET_SUCCESS]: providerWalletSuccess,
    [SettlementTypes.VERIFY_ACCOUNT_SUCCESS]: verifyAccountSuccess,
    [SettlementTypes.DISABLE_PAYSTACK_SUCCESS]: disablePaystackSuccess,
    [SettlementTypes.CONFIRM_DISABLE_SUCCESS]: confirmDisableSuccess,
    [SettlementTypes.APPROVE_SETTLEMENT_SUCCESS]: approveSettlementSuccess,
    [SettlementTypes.PAYMENT_SOURCES_SUCCESS]: paymentSourcesSuccess,
    [SettlementTypes.REVERSE_SETTLEMENT_SUCCESS]: reverseSettlementSuccess,

    [SettlementTypes.SETTLEMENT_FAILURE]: oneFailure,
    [SettlementTypes.SETTLEMENT_BUSINESS_FAILURE]: failure,
    [SettlementTypes.SETTLEMENT_ALL_FAILURE]: allFailure,
    [SettlementTypes.SETTLEMENT_COUNT_FAILURE]: countFailure,
    [SettlementTypes.SETTLEMENT_FILTER_FAILURE]: filterFailure,
    [SettlementTypes.SETTLEMENT_UPDATE_FAILURE]: updateFailure,
    [SettlementTypes.SETTLEMENT_DELETE_FAILURE]: deleteFailure,
    [SettlementTypes.PROVIDER_WALLET_FAILURE]: providerWalletFailure,
    [SettlementTypes.VERIFY_ACCOUNT_FAILURE]: verifyAccountFailure,
    [SettlementTypes.DISABLE_PAYSTACK_FAILURE]: disablePaystackFailure,
    [SettlementTypes.CONFIRM_DISABLE_FAILURE]: confirmDisableFailure,
    [SettlementTypes.APPROVE_SETTLEMENT_FAILURE]: approveSettlementFailure,
    [SettlementTypes.PAYMENT_SOURCES_FAILURE]: paymentSourcesFailure,
    [SettlementTypes.REVERSE_SETTLEMENT_FAILURE]: reverseSettlementFailure


})
