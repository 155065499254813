import { createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";
import { VerificationTypes } from "./verifications-actions";

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    fetchingPendingVerificationList: null,
    fetchingApprovedVerificationList: null,
    fetchingRejectedVerificationList: null,
    fetchingPendingVerificationCount: null,
    fetchingApprovedVerificationCount: null,
    fetchingRejectedVerificationCount: null,

    //Detail page
    fetchingList : false,
    fetchingVerification: false,
    approving: false,
    rejecting: false,
    fetchingProof:false,
    fetchingImage:false,

    pendingVerificationList: null,
    approvedVerificationList: null,
    rejectedVerificationList: null,
    pendingVerificationCount: null,
    approvedVerificationCount: null,
    rejectedVerificationCount: null,

    //Detail page
    verificationList: null,
    verification: null,
    verificationApproved: false,
    verificationRejected: false,
    verificationProof: null,
    image: null,

    errorPendingVerificationList: null,
    errorApprovedVerificationList: null,
    errorRejectedVerificationList: null,
    errorPendingVerificationCount: null,
    errorApprovedVerificationCount: null,
    errorRejectedVerificationCount: null,

    //Detail page
    errorFetching: null,
    errorFetchingOne: null,
    errorApproving: null,
    errorRejecting: null,
    errorFetchingProof: null,
    errorFetchingImage: null
})

/* ------------- Reducers ------------- */

// request the verification list from an api
export const pendingVerificationListRequest = (state) => { return { ...state, fetchingPendingVerificationList: true, pendingVerificationList: null } }

// request the approved verification list from an api 
export const approvedVerificationListRequest = (state) => { return { ...state, fetchingApprovedVerificationCount: true, approvedVerificationList: null } }

// request the rejected verification list from an api 
export const rejectedVerificationListRequest = (state) => { return { ...state, fetchingRejectedVerificationCount: true, rejectedVerificationList: null } }

// request the pending verification count from an api 
export const pendingVerificationCount = (state) => { return { ...state, fetchingPendingVerificationCount: true, pendingVerificationCount: null } }

// request the approved verification count from an api 
export const approvedVerificationCount = (state) => { return { ...state, fetchingApprovedVerificationCount: true, approvedVerificationCount: null } }

// request the rejected verification count from an api 
export const rejectedVerificationCount = (state) => { return { ...state, fetchingRejectedVerificationCount: true, rejectedVerificationCount: null } }

export const verificationListRequest = (state) => {
    return {...state, fetchingList: true}
}

export const verificationRequest = (state) => {
    return {...state, fetchingVerification: true}
}

export const verificationApproveRequest = (state) => {
    return {...state, approving: true, verificationApproved:false}
}

export const verificationRejectRequest = (state) => {
    return {...state, rejecting: true, verificationRejected:false, errorRejecting:null}
}
export const proofRequest = (state) => {
    return {...state, fetchingProof: true}
}
export const imageRequest = (state) => {
    return {...state, fetchingImage: true}
}

// successful api lookup for verification list 
export const pendingVerificationListSuccess = (state, action) => {
    const { pendingVerificationList } = action
    return {
        ...state,
        fetchingPendingVerificationList: false,
        errorPendingVerificationList: null,
        pendingVerificationList
    }
}

// successful api lookup for the approved verification list 
export const approvedVerificationListSuccess = (state, action) => {
    const { approvedVerificationList } = action
    return {
        ...state,
        fetchingApprovedVerificationList: false,
        errorApprovedVerificationList: null,
        approvedVerificationList
    }
}
// successful api lookup for the rejected verification list 
export const rejectedVerificationListSuccess = (state, action) => {
    const { rejectedVerificationList } = action
    return {
        ...state,
        fetchingRejectedVerificationList: false,
        errorRejectedVerificationList: null,
        rejectedVerificationList
    }
}

// successful api lookup for the pending verification count 
export const pendingVerificationCountSuccess = (state, action) => {
    const { count } = action
    return {
        ...state,
        fetchingPendingVerificationCount: false,
        errorPendingVerificationCount: null,
        pendingVerificationCount: count
    }
}

// successful api lookup for the approved verification count 
export const approvedVerificationCountSuccess = (state, action) => {
    const { approvedCount } = action
    return {
        ...state,
        fetchingApprovedVerificationCount: false,
        error: null,
        approvedVerificationCount: approvedCount
    }
}

// successful api lookup for the rejected verification count 
export const rejectedVerificationCountSuccess = (state, action) => {
    const { rejectedCount } = action
    return {
        ...state,
        fetchingRejectedVerificationCount: false,
        error: null,
        rejectedVerificationCount: rejectedCount
    }
}

export const verificationListSuccess = (state, action) => {
    const {verificationList} = action
    return{...state, verificationList, fetchingList: false, errorFetching: null}
}

export const verificationSuccess = (state, action) => {
    const {verification} = action
    return{...state, verification, fetchingVerification: false, errorFetchingOne: null}
}

export const verificationApproveSuccess = (state) => {
    // const {verificationApproved} = action
    return {...state, verificationApproved:true, errorApproving: null, approving:false}
}
// export const verificationApproveSuccess = (state, action) => {
//     const {verificationApproved} = action
//     return {...state, verificationApproved, errorApproving: null, approving:false}
// }

export const verificationRejectSuccess = (state, action) => {
    const {verificationRejected} = action
    return {...state, verificationRejected:true, errorRejecting: null, rejecting:false}
}
export const proofSuccess = (state, action) => {
    const {verificationProof} = action
    return {...state, verificationProof, errorFetchingProof: null, fetchingProof: false}
}
export const imageSuccess = (state, action) => {
    const {image} = action
    // console.log(image);
    // console.log(action, "action");
    return {...state, image, errorFetchingImage: null}
}
// export const imageSuccess = (state, action) => {
//     const {image} = action
//     console.log(image);
//     console.log(action, "action");
//     return {...state, image:Buffer.from(image, 'binary').toString('base64'), errorFetchingImage: null}
// }


// something went wrong fetching pending verification list
export const pendingVerificationListFailure = (state, action) => {
    const { error } = action
    return {
        ...state,
        fetchingPendingVerificationList: false,
        errorPendingVerificationList: error,
        pendingVerificationList: null
    }
}

// something went wrong fetching approved verification list
export const approvedVerificationListFailure = (state, action) => {
    const { error } = action
    return {
        ...state,
        fetchingApprovedVerificationList: false,
        errorApprovedVerificationList: error,
        approvedVerificationList: null
    }
}
// something went wrong fetching rejected verification list
export const rejectedVerificationListFailure = (state, action) => {
    const { error } = action
    return {
        ...state,
        fetchingRejectedVerificationList: false,
        errorRejectedVerificationList: error,
        rejectedVerificationList: null
    }
}

// something went wrong fetching pending verifications count
export const pendingVerificationCountFailure = (state, action) => {
    const { error } = action
    return {
        ...state,
        fetchingPendingVerificationCount: false,
        errorPendingVerificationCount: error,
        pendingVerificationCount: null
    }
}

// something went wrong fetching approved verifications count
export const approvedverificationCountFailure = (state, action) => {
    const { error } = action
    return {
        ...state,
        fetchingApprovedVerificationCount: false,
        errorApprovedVerificationCount: error,
        approvedVerificationCount: null
    }
}

// something went wrong fetching rejected verifications count
export const rejectedVerificationCountFailure = (state, action) => {
    const { error } = action
    return {
        ...state,
        fetchingRejectedVerificationCount: false,
        errorRejectedVerificationCount: error,
        rejecetedVerificationCount: null
    }
}

export const verificationListFailure = (state, action) => {
    const {error} = action
    return {...state, errorFetching: error, fetchingList: false}
}

export const verificationFailure = (state, action) => {
    const {error} = action
    return {...state, errorFetchingOne: error, fetchingDocument: false}
}

export const verificationApproveFailure = (state, action) => {
    const {error} = action
    return {...state, errorApproving: error,  approving:false,}
}

export const verificationRejectFailure = (state, action) => {
    const {error} = action
    return {...state, errorRejecting: error,  rejecting:false,}
}
export const proofFailure = (state, action) => {
    const {error} = action
    return {...state, errorFetchingProof: error, fetchingProof: false}
}
export const imageFailure = (state, action) => {
    const {error} = action
    return {...state, errorFetchingImage: error, fetchingImage: false}
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [VerificationTypes.PENDING_VERIFICATION_LIST_REQUEST]: pendingVerificationListRequest,
    [VerificationTypes.APPROVED_VERIFICATION_LIST_REQUEST]: approvedVerificationListRequest,
    [VerificationTypes.REJECTED_VERIFICATION_LIST_REQUEST]: rejectedVerificationListRequest,
    [VerificationTypes.PENDING_VERIFICATION_COUNT]: pendingVerificationCount,
    [VerificationTypes.APPROVED_VERIFICATION_COUNT]: approvedVerificationCount,
    [VerificationTypes.REJECTED_VERIFICATION_COUNT]: rejectedVerificationCount,
    //Deatail page
    [VerificationTypes.VERIFICATION_LIST_REQUEST]: verificationListRequest,
    [VerificationTypes.VERIFICATION_REQUEST]: verificationRequest,
    [VerificationTypes.VERIFICATION_APPROVE_REQUEST]: verificationApproveRequest,
    [VerificationTypes.VERIFICATION_REJECT_REQUEST]: verificationRejectRequest,
    [VerificationTypes.PROOF_REQUEST]: proofRequest,
    [VerificationTypes.IMAGE_REQUEST]: imageRequest,

    [VerificationTypes.PENDING_VERIFICATION_LIST_SUCCESS]: pendingVerificationListSuccess,
    [VerificationTypes.APPROVED_VERIFICATION_LIST_SUCCESS]: approvedVerificationListSuccess,
    [VerificationTypes.REJECTED_VERIFICATION_LIST_SUCCESS]: rejectedVerificationListSuccess,
    [VerificationTypes.PENDING_VERIFICATION_COUNT_SUCCESS]: pendingVerificationCountSuccess,
    [VerificationTypes.APPROVED_VERIFICATION_COUNT_SUCCESS]: approvedVerificationCountSuccess,
    [VerificationTypes.REJECTED_VERIFICATION_COUNT_SUCCESS]: rejectedVerificationCountSuccess,
    //Detail page
    [VerificationTypes.VERIFICATION_LIST_SUCCESS]: verificationListSuccess,
    [VerificationTypes.VERIFICATION_SUCCESS]: verificationSuccess,
    [VerificationTypes.VERIFICATION_APPROVE_SUCCESS]: verificationApproveSuccess,
    [VerificationTypes.VERIFICATION_REJECT_SUCCESS]: verificationRejectSuccess,
    [VerificationTypes.PROOF_SUCCESS]: proofSuccess,
    [VerificationTypes.IMAGE_SUCCESS]: imageSuccess,


    [VerificationTypes.PENDING_VERIFICATION_LIST_FAILURE]: pendingVerificationListFailure,
    [VerificationTypes.APPROVED_VERIFICATION_LIST_FAILURE]: approvedVerificationListFailure,
    [VerificationTypes.REJECTED_VERIFICATION_LIST_FAILURE]: rejectedVerificationListFailure,
    [VerificationTypes.PENDING_VERIFICATION_COUNT_FAILURE]: pendingVerificationCountFailure,
    [VerificationTypes.APPROVED_VERIFICATION_COUNT_FAILURE]: approvedverificationCountFailure,
    [VerificationTypes.REJECTED_VERIFICATION_COUNT_FAILURE]: rejectedVerificationCountFailure,
    //Detail page
    [VerificationTypes.VERIFICATION_LIST_FAILURE]: verificationListFailure,
    [VerificationTypes.VERIFICATION_FAILURE]: verificationFailure,
    [VerificationTypes.VERIFICATION_APPROVE_FAILURE]: verificationApproveFailure,
    [VerificationTypes.VERIFICATION_REJECT_FAILURE]: verificationRejectFailure,
    [VerificationTypes.PROOF_FAILURE]: proofFailure,
    [VerificationTypes.IMAGE_FAILURE]: imageFailure,
})