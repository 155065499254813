import { createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    accountRequest: [],

    accountSuccess: ["userAccount"],

    accountFailure: ["error"],
});

export const AccountTypes = Types;
export default Creators;
