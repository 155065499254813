import { createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    indexSubscribersRequest: [],
    indexPaymentsRequest: [],
    indexSignupRequest: [],
    indexCustomersRequest: [],
    indexPaymentSchedulesRequest: [],
    indexPlansRequest: [],
    indexBusinessCustomersRequest: [],
    indexActivitiesRequest: [],
    indexSettlementsRequest: [],

    indexSubscribersSuccess: [],
    indexPaymentsSuccess: [],
    indexSignupSuccess: [],
    indexCustomersSuccess: [],
    indexPaymentSchedulesSuccess: [],
    indexPlansSuccess: [],
    indexBusinessCustomersSuccess: [],
    indexActivitiesSuccess: [],
    indexSettlementsSuccess: [],


    indexSubscribersFailure: ["error"],
    indexPaymentsFailure: ["error"],
    indexSignupFailure: ["error"],
    indexCustomersFailure: ["error"],
    indexPaymentSchedulesFailure: ["error"],
    indexPlansFailure: ["error"],
    indexBusinessCustomersFailure: ["error"],
    indexActivitiesFailure: ["error"],
    indexSettlementsFailure: ["error"],
});

export const IndexingTypes = Types;
export default Creators;
