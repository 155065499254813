import { createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";
import { BusinessTypes } from "./business-actions";

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetchingOne: null,
  fetchingAll: null,
  fetchingBusinessesCount: null,
  fetchingCacheBusinesses: null,
  fetchingEnterpriseCustomers: null,
  fetchingEnterpriseCustomersCount: null,
  fetchingVerifiedBusinessesDetails: null,
  enablingCardPayments: null,
  overridingCardFees: null,
  overridingMomoFees: null,
  passingFeesOn: null,
  updating: null,
  deleting: null,
  verificationInProgress: null,
  fetchingBusinessVerification: false,

  business: null,
  businesses: null,
  cacheBusinesses: null,
  enterpriseCustomers: null,
  enterpriseCustomersCount: null,
  businessesCount: null,
  verifiedBusinessesDetails: null,
  verificationStarted: null,
  businessVerification: null,

  errorEnablingCardPayments: null,
  errorOverridingCardFees: null,
  errorOverridingMomoFees: null,
  errorPassingFeesOn: null,
  errorOne: null,
  errorAll: null,
  errorUpdating: null,
  errorDeleting: null,
  errorBusinessesCount: null,
  errorCacheBusinesses: null,
  errorEnterpriseCustomers: null,
  errorEnterpriseCustomersCount: null,
  errorVerifiedBusinessesDetails: null,
  errorVerifying: null,
  errorBusinessVerification: null,

});

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state) => {
  return { ...state, fetchingOne: true, business: null };
};
// request the data from an api
export const allRequest = (state) => {
  return { ...state, fetchingAll: true, business: null };
};
// request the data from an api
export const businessesCountRequest = (state) => {
  return { ...state, fetchingCount: true, count: null };
};
// request to update from an api
export const updateRequest = (state) => {
  return { ...state, updating: true };
};
// request to delete from an api
export const deleteRequest = (state) => {
  return { ...state, deleting: true };
};
// request the filtered Business from an api 
export const cacheBusinessesRequest = (state) => {
  return { ...state, fetchingCacheBusinesses: true, cacheBusinesses: null };
};

// request the enterpriseCustomers from an api 
export const enterpriseCustomersRequest = (state) => {
  return { ...state, fetchingEnterpriseCustomers: true, enterpriseCustomers: null };
};

//request the enterprise customers count from an api
export const enterpriseCustomersCountRequest = (state) => {
  return { ...state, fetchingEnterpriseCustomersCount: true, enterpriseCustomersCount: null };
};

//request the verified businesses details from an api
export const approvedBusinessesDetailRequest = (state) => {
  return { ...state, fetchingVerifiedBusinessesDetails: true, verifiedBusinessesDetails: null };
};

// request to enable passing fees from an api
export const passingFeesOnRequest = (state) => {
  return { ...state, passingFeesOn: true };
};

// request to enable card payment from an api
export const enableCardPaymentsRequest = (state) => {
  return { ...state, enablingCardPayments: true };
};

// request to override card fees from an api
export const overrideCardFeesRequest = (state) => {
  return { ...state, overridingCardFees: true };
};

// request to override momo fees from an api
export const overrideMomoFeesRequest = (state) => {
  return { ...state, overridingMomoFees: true };
};

// verification started request from api
export const verificationStartedRequest = (state) => {
  return { ...state, verificationInProgress: true, verificationStarted: null };
};
//business verification request
export const businessVerificationRequest = (state) => {
  return { ...state, fetchingBusinessVerification: true, businessVerification: null }
}

// successful api lookup for single entity
export const success = (state, action) => {
  const { business } = action;
  return { ...state, fetchingOne: false, errorOne: null, business };
};
// successful api lookup for all entities
export const allSuccess = (state, action) => {
  const { businesses } = action;
  return { ...state, fetchingAll: false, errorAll: null, businesses };
};
// successful api lookup for all count entities
export const businessesCountSuccess = (state, action) => {
  const { businessesCount } = action;
  return { ...state, fetchingCount: false, errorCount: null, businessesCount: businessesCount };
};
// successful api update
export const updateSuccess = (state, action) => {
  const { business } = action;
  return { ...state, updating: false, errorUpdating: null, business };
};
// successful api delete
export const deleteSuccess = (state) => {
  return { ...state, deleting: false, errorDeleting: null, business: null };
};
// successful api filtered business
export const cacheBusinessesSuccess = (state, action) => {
  const { cacheBusinesses } = action;
  return { ...state, fetchingCacheBusinesses: false, errorCacheBusinesses: null, cacheBusinesses: cacheBusinesses };
};
// successful api for enterprise customers
export const enterpriseCustomersSuccess = (state, action) => {
  const { enterpriseCustomers } = action;
  return { ...state, fetchingEnterpriseCustomers: false, errorEnterpriseCustomers: null, enterpriseCustomers: enterpriseCustomers }
}

// successful api for enterprise customers count
export const enterpriseCustomersCountSuccess = (state, action) => {
  const { enterpriseCustomersCount } = action;
  return { ...state, fetchingEnterpriseCustomersCount: false, errorEnterpriseCustomersCount: null, enterpriseCustomersCount: enterpriseCustomersCount }
}

// successfull api for verified businesses detail
export const approvedBusinessesDetailSuccess = (state, action) => {
  const { approvedVerificationDetails } = action;
  return { ...state, fetchingVerifiedBusinessesDetails: false, errorVerifiedBusinessesDetails: null, verifiedBusinessesDetails: approvedVerificationDetails }
}

// successful api for enablingCardPayments
export const enableCardPaymentsSuccess = (state, action) => {
  const { business } = action;
  return { ...state, enablingCardPayments: false, errorEnablingCardPayments: null, business };
};
// successful api for overridingCardFees
export const overrideCardFeesSuccess = (state, action) => {
  const { business } = action;
  return { ...state, overridingCardFees: false, errorOverridingCardFees: null, business };
};
// successful api for overridingMomoFees
export const overrideMomoFeesSuccess = (state, action) => {
  const { business } = action;
  return { ...state, overridingMomoFees: false, errorOverridingMomoFees: null, business };
};

// successful api for passingFeesOn
export const passingFeesOnSuccess = (state, action) => {
  const { business } = action;
  return { ...state, passingFeesOn: false, errorPassingFeesOn: null, business };
};

// successful verification started api request
export const verificationStartedSuccess = (state, action) => {
  const { verificationStarted } = action;
  return { ...state, verificationInProgress: false, verificationStarted, errorVerifying: null, }
};
//successful business verification call
export const businessVerificationSuccess = (state, action) => {
  const { businessVerification } = action;
  return { ...state, fetchingBusinessVerification: false, errorBusinessVerification: null, businessVerification }
}

// Something went wrong fetching a single entity.
export const failure = (state, action) => {
  const { error } = action;
  return { ...state, fetchingOne: false, errorOne: error, business: null };
};
// Something went wrong fetching all entities.
export const allFailure = (state, action) => {
  const { error } = action;
  return { ...state, fetchingAll: false, errorAll: error, businesses: null };
};
// Something went wrong fetching count entities.
export const businessesCountFailure = (state, action) => {
  const { error } = action;
  return { ...state, fetchingCount: false, errorCount: error, count: null };
};
// Something went wrong updating.
export const updateFailure = (state, action) => {
  const { error } = action;
  return { ...state, updating: false, errorUpdating: error };
};
// Something went wrong deleting.
export const deleteFailure = (state, action) => {
  const { error } = action;
  return { ...state, deleting: false, errorDeleting: error };
};
// Something went fetching filtered business
export const cacheBusinessesFailure = (state, action) => {
  const { error } = action;
  return { ...state, fetchingCacheBusinesses: false, errorCacheBusinesses: error };
};

// Something went fetching enterprise customers
export const enterpriseCustomersFailure = (state, action) => {
  const { error } = action;
  return { ...state, fetchingEnterpriseCustomers: false, errorEnterpriseCustomers: error };
};

export const enterpriseCustomersCountFailure = (state, action) => {
  const { error } = action;
  return { ...state, fetchingEnterpriseCustomersCount: false, errorEnterpriseCustomersCount: error };
};

// something went wrong fetching verified businesseses details
export const approvedBusinessesDetailFailure = (state, action) => {
  const { error } = action;
  return { ...state, fetchingVerifiedBusinessesDetails: false, errorVerifiedBusinessesDetails: error };
}

// Something went wrong enablingCardPayments.
export const enableCardPaymentsFailure = (state, action) => {
  const { error } = action;
  return { ...state, enablingCardPayments: false, errorEnablingCardPayments: error };
};

// Something went wrong overridingMomoFees
export const overrideMomoFeesFailure = (state, action) => {
  const { error } = action;
  return { ...state, overridingMomoFees: false, errorOverridingMomoFees: error };
};

// Something went wrong overridingMomoFees
export const overrideCardFeesFailure = (state, action) => {
  const { error } = action;
  return { ...state, overridingCardFees: false, errorOverridingCardFees: error };
};

// Something went wrong overridingMomoFees
export const passingFeesOnFailure = (state, action) => {
  const { error } = action;
  return { ...state, passingFeesOn: false, errorPassingFeesOn: error };
};

// Something went wrong for verification started request.
export const verificationStartedFailure = (state, action) => {
  const { error } = action;
  return { ...state, verificationInProgress: false, verificationStarted: null, errorVerifying: error };
};
//something went wrong fetching business verification request
export const businessVerificationFailure = (state, action) => {
  const { error } = action;
  return { ...state, fetchingBusinessVerification: false, errorBusinessVerification: error, businessVerification: null }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [BusinessTypes.BUSINESS_REQUEST]: request,
  [BusinessTypes.BUSINESS_ALL_REQUEST]: allRequest,
  [BusinessTypes.BUSINESSES_COUNT_REQUEST]: businessesCountRequest,
  [BusinessTypes.BUSINESS_UPDATE_REQUEST]: updateRequest,
  [BusinessTypes.BUSINESS_DELETE_REQUEST]: deleteRequest,
  [BusinessTypes.CACHE_BUSINESSES_REQUEST]: cacheBusinessesRequest,
  [BusinessTypes.ENTERPRISE_CUSTOMERS_REQUEST]: enterpriseCustomersRequest,
  [BusinessTypes.ENTERPRISE_CUSTOMERS_COUNT_REQUEST]: enterpriseCustomersCountRequest,
  [BusinessTypes.APPROVED_BUSINESSES_DETAIL_REQUEST]: approvedBusinessesDetailRequest,
  [BusinessTypes.ENABLE_CARD_PAYMENTS_REQUEST]: enableCardPaymentsRequest,
  [BusinessTypes.OVERRIDE_CARD_FEES_REQUEST]: overrideCardFeesRequest,
  [BusinessTypes.OVERRIDE_MOMO_FEES_REQUEST]: overrideMomoFeesRequest,
  [BusinessTypes.PASSING_FEES_ON_REQUEST]: passingFeesOnRequest,
  [BusinessTypes.VERIFICATION_STARTED_REQUEST]: verificationStartedRequest,
  [BusinessTypes.BUSINESS_VERIFICATION_REQUEST]: businessVerificationRequest,

  [BusinessTypes.BUSINESS_SUCCESS]: success,
  [BusinessTypes.BUSINESSES_COUNT_SUCCESS]: businessesCountSuccess,
  [BusinessTypes.BUSINESS_ALL_SUCCESS]: allSuccess,
  [BusinessTypes.BUSINESS_UPDATE_SUCCESS]: updateSuccess,
  [BusinessTypes.BUSINESS_DELETE_SUCCESS]: deleteSuccess,
  [BusinessTypes.CACHE_BUSINESSES_SUCCESS]: cacheBusinessesSuccess,
  [BusinessTypes.ENTERPRISE_CUSTOMERS_SUCCESS]: enterpriseCustomersSuccess,
  [BusinessTypes.ENTERPRISE_CUSTOMERS_COUNT_SUCCESS]: enterpriseCustomersCountSuccess,
  [BusinessTypes.APPROVED_BUSINESSES_DETAIL_SUCCESS]: approvedBusinessesDetailSuccess,
  [BusinessTypes.ENABLE_CARD_PAYMENTS_SUCCESS]: enableCardPaymentsSuccess,
  [BusinessTypes.OVERRIDE_CARD_FEES_SUCCESS]: overrideCardFeesSuccess,
  [BusinessTypes.OVERRIDE_MOMO_FEES_SUCCESS]: overrideMomoFeesSuccess,
  [BusinessTypes.PASSING_FEES_ON_SUCCESS]: passingFeesOnSuccess,
  [BusinessTypes.VERIFICATION_STARTED_SUCCESS]: verificationStartedSuccess,
  [BusinessTypes.BUSINESS_VERIFICATION_SUCCESS]: businessVerificationSuccess,

  [BusinessTypes.BUSINESS_FAILURE]: failure,
  [BusinessTypes.BUSINESS_ALL_FAILURE]: allFailure,
  [BusinessTypes.BUSINESSES_COUNT_FAILURE]: businessesCountFailure,
  [BusinessTypes.BUSINESS_UPDATE_FAILURE]: updateFailure,
  [BusinessTypes.BUSINESS_DELETE_FAILURE]: deleteFailure,
  [BusinessTypes.CACHE_BUSINESSES_FAILURE]: cacheBusinessesFailure,
  [BusinessTypes.ENTERPRISE_CUSTOMERS_FAILURE]: enterpriseCustomersFailure,
  [BusinessTypes.ENTERPRISE_CUSTOMERS_COUNT_FAILURE]: enterpriseCustomersCountFailure,
  [BusinessTypes.APPROVED_BUSINESSES_DETAIL_FAILURE]: approvedBusinessesDetailFailure,
  [BusinessTypes.ENABLE_CARD_PAYMENTS_FAILURE]: enableCardPaymentsFailure,
  [BusinessTypes.OVERRIDE_CARD_FEES_FAILURE]: overrideCardFeesFailure,
  [BusinessTypes.OVERRIDE_MOMO_FEES_FAILURE]: overrideMomoFeesFailure,
  [BusinessTypes.PASSING_FEES_ON_FAILURE]: passingFeesOnFailure,
  [BusinessTypes.VERIFICATION_STARTED_FAILURE]: verificationStartedFailure,
  [BusinessTypes.BUSINESS_VERIFICATION_FAILURE]: businessVerificationFailure,
});
