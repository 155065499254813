import { createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    filteredDlrsRequest: ["businessId", "options"],
    dlrsAllRequest: ["options"],
    allDlrsCountRequest: [],
    filteredCountDlrsRequest: ["businessId"],

    filteredDlrsSuccess: ["dlr"],
    dlrsAllSuccess: ["dlrs"],
    allDlrsCountSuccess: ["allDlrsCount"],
    filteredCountDlrsSuccess: ["countDLRs"],

    filteredDlrsFailure: ["error"],
    dlrsAllFailure: ["error"],
    allDlrsCountFailure: ["error"],
    filteredCountDlrsFailure: ["error"],

})

export const DLRsTypes = Types;
export default Creators