import { lazy } from "react";

//Import Layouts
// const BlankLayout = lazy(() => import("../layouts/BlankLayout"));
// const ImplicitLayout = lazy(() => import("../layouts/ImplicitLayout"))

//Import Components
const BusinessDetail = lazy(() => import('../views/businesses/business-detail/index'))
const SettlementDetail = lazy(() => import('../views/settlements/settlement-detail'))
const SubscriptionDetail = lazy(() => import('../views/subscriptions/subscriptions-detail'))
const IdentityDocs = lazy(() => import('../views/countries/identity-documents/identity-docs'))
const CreateIdentityDoc = lazy(() => import('../views/countries/identity-documents/create-id-doc'))
const EditIdentityDoc = lazy(() => import('../views/countries/identity-documents/edit-id-doc'))
const SettlementsList = lazy(() => import('../views/settlements/index'))
const ScheduledPaymentsList = lazy(() => import('../views/scheduled-payments/scheduled-payments-list'));
const Businesses = lazy(() => import('../views/businesses/businesses'))
const Subscriptions = lazy(() => import('../views/subscriptions/subscriptions-list'));
const Indexing = lazy(() => import('../views/indexing/indexing'));
const Countries = lazy(() => import('../views/countries/countries'));
const Customers = lazy(() => import('../views/customers/customers'))
const Verification = lazy(() => import('../views/verifications/index-verification'))
const SystemBanks = lazy(() => import('../views/countries/system-banks/system-banks-list'))
const SystemBankDetail = lazy(() => import('../views/countries/system-banks/system-bank-detail'))
const EditCountry = lazy(() => import('../views/countries/edit-country'))
const VerificationDetails = lazy(() => import('../views/verifications/details'))
const DLRs = lazy(() => (import('../views/dlrs/index')))
const EmailTemplate = lazy(() => (import('../views/email-template/index')))

//Routes List
export const routes = [
  { path: '/businesses/:id', name: "Business Detail", component: BusinessDetail },
  { path: '/settlements/:id', name: "Settlement Detail", component: SettlementDetail },
  { path: '/subscriptions/:id', name: "Subscription Detail", component: SubscriptionDetail },
  { path: '/countries/identity-documents/edit/:id', name: "Edit Identity Document", component: EditIdentityDoc },
  { path: '/countries/edit-country/:id', name: "Edit Country", component: EditCountry },
  { path: '/countries/identity-documents/create', name: "Create Identity Document", component: CreateIdentityDoc },
  { path: '/countries/identity-documents/:id', name: "Identity Document", component: IdentityDocs },
  { path: '/countries/system-banks/detail/:id', name: "Bank Detail", component: SystemBankDetail },
  { path: "/countries/system-banks/:id", name: "Banks", component: SystemBanks },
  { path: "/verifications/:id", name: "Verification Detail", component: VerificationDetails },
  { path: "/settlements", name: "settlements", component: SettlementsList, },
  { path: "/scheduled-payments", name: "schedule", component: ScheduledPaymentsList, },
  { path: "/businesses", name: "business", component: Businesses, },
  { path: "/subscriptions", name: "subscriptions", component: Subscriptions, },
  { path: "/indexing", name: "indexing", component: Indexing, },
  { path: "/countries", name: "countries", component: Countries, },
  { path: "/customers", name: "customers", component: Customers, },
  { path: "/verifications", name: "Verification Requests", component: Verification },
  { path: "/dlrs", name: "dlrs", component: DLRs },
  { path: "/emailTemplate", name: "Email Template", component: EmailTemplate }
];