import { createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    isAuthorizedUser: ["status"],
});

export const AuthorizationTypes = Types;
export default Creators;
