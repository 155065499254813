import React from 'react';
import img1 from '../../assets/images/reveknew.png';
import img2 from '../../assets/images/background/safe_lock.png';
import { useTranslation } from 'react-i18next';
import { removeAuthToken } from "../../services/api";
import { Button } from 'reactstrap';
import { useKeycloak } from '@react-keycloak/web';

const AccessDenied = () => {
    const { t } = useTranslation();
    const { keycloak, } = useKeycloak();

    const onClickLogout = () => {
        removeAuthToken();
        keycloak.logout({
            redirectUri: process.env.REACT_APP_LOGOUT_URL, // Redirect to localhost:3000 after logout
            });

    }

    return <div className="">
        <div className="access-box">
            <div className="mt-4 ml-4">
                <img src={img1} alt="" width={150} />
                <h5>{t("common.console_name")}</h5>
            </div>
            <div className="access-body text-center mt-5 pt-5">
                <h1 className="text-dark font-bold" >{t("access_denied.error_code")}</h1>
                <div className="mt-4 mb-5">
                    <h2><b>{t("access_denied.error_header")}</b></h2>
                    <h4 className="mb-3 text-muted font-medium">{t("access_denied.error_message")}</h4>
                    <Button onClick={onClickLogout} style={{ color: 'white', backgroundColor: '#3E82FE', borderRadius: 7 }} >
                        <i className="fas fa-sign-out-alt mr-2 fa-lg" aria-hidden="true"></i>
                        <b>{t("common.logout")}</b>
                    </Button>
                </div>
                <img src={img2} alt={""} height={450} />
                <div className="mt-4"></div>
            </div>
        </div>
    </div>;
}

export default AccessDenied;
