import { createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    settlementRequest: ['settlementId'],
    settlementBusinessRequest: ['businessId', 'options'],
    settlementAllRequest: ['options'],
    settlementCountRequest: ['filter'],
    settlementFilterRequest: ['filter', 'options'],
    settlementUpdateRequest: ['settlement'],
    settlementDeleteRequest: ['settlementId', 'options'],
    providerWalletRequest: [],
    verifyAccountRequest: ["systemBankId", "identifier"],
    disablePaystackRequest: [],
    confirmDisableRequest: ["otp"],
    approveSettlementRequest: ['settlementId'],
    paymentSourcesRequest: ['settlementId'],
    reverseSettlementRequest: ['settlementId', 'data'],

    settlementSuccess: ['settlement'],
    settlementBusinessSuccess: ['settlement'],
    settlementAllSuccess: ['settlements'],
    settlementCountSuccess: ['count'],
    settlementFilterSuccess: ['filtered'],
    settlementUpdateSuccess: ['settlement'],
    settlementDeleteSuccess: [],
    providerWalletSuccess: ["wallets"],
    verifyAccountSuccess: ["details"],
    disablePaystackSuccess: ["disabledOtp"],
    confirmDisableSuccess: [],
    approveSettlementSuccess: ['data'],
    paymentSourcesSuccess: ['paymentSources'],
    reverseSettlementSuccess: ['reversedSettlements'],


    settlementFailure: ['error'],
    settlementBusinessFailure: ['error'],
    settlementAllFailure: ['error'],
    settlementCountFailure: ['error'],
    settlementFilterFailure: ['error'],
    settlementUpdateFailure: ['error'],
    settlementDeleteFailure: ['error'],
    providerWalletFailure: ['error'],
    verifyAccountFailure: ['error'],
    disablePaystackFailure: ['error'],
    confirmDisableFailure: ["error"],
    approveSettlementFailure: ['error'],
    paymentSourcesFailure: ['error'],
    reverseSettlementFailure: ['error'],

})

export const SettlementTypes = Types
export default Creators
