import { createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    systemBankListRequest: ["countryId", "options"],
    systemBankRequest: ["Id",],
    systemBanksCountRequest: ["countryId"],
    logoRequest: ["bankId", "file"],
    disableSystemBankRequest: ["bankId"],
    providerBanksRequest: ["systemBankId"],
    providersRequest: [],
    bankPerProviderRequest: ["paymentProvider"],
    linkRequest: ["systemBankId", "providerBankId"],
    unlinkRequest: ["systemBankId", "providerBankId"],

    systemBankListSuccess: ["systemBankList"],
    systemBankSuccess: ["systemBank"],
    systemBanksCountSuccess: ["count"],
    logoSuccess: ["logo"],
    disableSystemBankSuccess: [],
    providerBanksSuccess: ["providerBanks"],
    providersSuccess: ["providers"],
    bankPerProviderSuccess: ["banks"],
    linkSuccess: [],
    unlinkSuccess: [],

    systemBankListFailure: ["error"],
    systemBankFailure: ["error"],
    systemBanksCountFailure: ["error"],
    logoFailure: ["error"],
    disableSystemBankFailure: ["error"],
    providerBanksFailure: ['error'],
    providersFailure: ['error'],
    bankPerProviderFailure: ['error'],
    linkFailure: ['error'],
    unlinkFailure: ['error']


})

export const SystemBankTypes = Types
export default Creators