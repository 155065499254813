import { createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    countryRequest: ["id"],
    countriesRequest: ["options"],
    identityDocumentRequest: ["idDocId"],
    identityDocumentsCountRequest: ["countryId"],
    identityDocumentsRequest: ["countryId", "options"],
    updateDocumentRequest: ["id", "identityDocument"],
    createDocumentRequest: ["identityDocument"],
    disableIdentityDocumentRequest: ["idDocId"],
    updateCountryRequest: ["countryId", "countryData"],
    zonesRequest: [],

    countrySuccess: ["country"],
    countriesSuccess: ["countries"],
    identityDocumentSuccess: ["identityDocument"],
    identityDocumentsCountSuccess: ["count"],
    identityDocumentsSuccess: ["identityDocuments"],
    updateDocumentSuccess: ["identityDocument"],
    createDocumentSuccess: ["identityDocument"],
    disableIdentityDocumentSuccess: [],
    updateCountrySuccess: ["countryData"],
    zonesSuccess: ["zones"],

    countryFailure: ["error"],
    countriesFailure: ["error"],
    identityDocumentFailure: ["error"],
    identityDocumentsCountFailure: ["error"],
    identityDocumentsFailure: ["error"],
    updateDocumentFailure: ["error"],
    createDocumentFailure: ["error"],
    disableIdentityDocumentFailure: ["error"],
    updateCountryFailure: ["error"],
    zonesFailure: ["error"],

    saveCountry: ["country"],
});



export const CountriesTypes = Types;
export default Creators;
