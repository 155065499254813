import React from "react";
import { routes } from "./routes/";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/Store";
import { PersistGate } from 'redux-persist/integration/react'
import Loading from './views/ui-components/Loading'
import "react-datetime/css/react-datetime.css";
import LoginScreen from "./views/authentication/LoginScreen";
import AccessDenied from "./views/authentication/AccessDenied";
import FullLayout from "./layouts/FullLayout.js";
import ImplicitCallback from "./layouts/ImplicitCallBack"


const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <Router>
          <Routes>
            {/* Static Routes */}
            <Route path="/authentication/access-denied" element={<AccessDenied />} />
            <Route path="/implicit/callback" element={<ImplicitCallback />} />
            <Route path="/" element={<LoginScreen />} />

            {/* Dynamic Routes with Layout */}
            {routes.map((route, index) => {
              const Layout = route.layout || FullLayout;
              const Component = route.component;
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <Layout>
                      <Component />
                    </Layout>
                  }
                />
              );
            })}
          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;